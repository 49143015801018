import { setState } from 'app/reducers/loading';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from 'types';

import { LoadState } from './LoadState';
import { _DispatchProps, _StateProps } from './types';

const dispatchToProps = (dispatch: Dispatch): _DispatchProps => ({
  setState: (state) => {
    dispatch(setState(state));
  },
});

const stateToProps = (state: StoreState): _StateProps => ({
  state: state.loading.state,
});

const ConnectedLoadState = connect(stateToProps, dispatchToProps)(LoadState);
export default ConnectedLoadState;
