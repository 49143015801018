import * as api from 'lib/go/utils';
import { fiber_bundle } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'fiber_bundles';

type IDOnly = fiber_bundle.FiberBundleIDOnly;
type Full = fiber_bundle.FiberBundle;
type Debug = fiber_bundle.FiberBundleDebug;

type CSIDOnly = fiber_bundle.ChildStructureIDOnly;
type CSFull = fiber_bundle.ChildStructure;
type CSDebug = fiber_bundle.ChildStructureDebug;

type PSIDOnly = fiber_bundle.ParentStructureIDOnly;
type PSFull = fiber_bundle.ParentStructure;
type PSDebug = fiber_bundle.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<fiber_bundle.Stats>(namespace, resource);
