import { add as addAnalysis } from 'app/reducers/drone/analysis';
import { add as addSelection, remove as removeSelection } from 'app/reducers/drone/selection';
import { push as pushMessage } from 'app/reducers/messages';
import { geojson, utils } from 'gridtools/types';
import * as intl from 'react-intl-universal';
import { call, put, takeEvery } from 'redux-saga/effects';

import { analyzeAPI } from './api';
import { point2square } from './utils';

type AnalyzeAction = ReturnType<typeof analyze>;

export type DroneAction =
  | AnalyzeAction;

const ANALYZE = 'sagas/drone/analyze';

export function analyze(coordinate: geojson.Coordinates) {
  const selection = point2square(coordinate);
  return { selection, type: ANALYZE as typeof ANALYZE };
}

function* analysisSaga(action: AnalyzeAction) {
  const selection = action.selection;
  if (selection !== null) {
    yield put(addSelection(selection));
    try {
      const api = () => analyzeAPI(selection);
      const response: utils.UnwrapPromise<ReturnType<typeof api>> = yield call(api);
      yield put(addAnalysis(response.data));
    } catch {
      const error = intl.get('actions.drone.error');
      yield put(pushMessage({ content: error }));
    }
    yield put(removeSelection(selection));
  }
}

export function* droneSaga() {
  yield takeEvery(ANALYZE, analysisSaga);
}
