import { LoadingOptions, LoadingState } from 'types';

const START_LOADING_LOCALES = 'store/loading/start-loading-locales';
const FINISH_LOADING_LOCALES = 'store/loading/finish-loading-locales';
const START_LOGGING_IN = 'store/loading/start-logging-in';
const FINISH_LOGGING_IN = 'store/loading/finish-logging-in';
const START_SAML_REQUEST = 'store/loading/start-saml-request';
const FINISH_SAML_REQUEST = 'store/loading/finish-saml-request';
const SET_STATE = 'store/loading/set-logged-in-app-state';

type StartLoadingLocalesAction = { type: typeof START_LOADING_LOCALES; };
type FinishLoadingLocalesAction = { type: typeof FINISH_LOADING_LOCALES; };
type StartLoggingInAction = { type: typeof START_LOGGING_IN; };
type FinishLoggingInAction = { type: typeof FINISH_LOGGING_IN; };
type StartSamlRequestAction = ReturnType<typeof startSamlRequest>;
type FinishSamlRequestAction = ReturnType<typeof finishSamlRequest>;
type SetStateAction = { type: typeof SET_STATE; options: Partial<LoadingOptions>; };

export type LoadingAction =
  | StartLoadingLocalesAction
  | FinishLoadingLocalesAction
  | StartLoggingInAction
  | FinishLoggingInAction
  | StartSamlRequestAction
  | FinishSamlRequestAction
  | SetStateAction;

export const startLoadingLocales = (): StartLoadingLocalesAction => ({ type: START_LOADING_LOCALES });
export const finishLoadingLocales = (): FinishLoadingLocalesAction => ({ type: FINISH_LOADING_LOCALES });
export const startLoggingIn = (): StartLoggingInAction => ({ type: START_LOGGING_IN });
export const finishLoggingIn = (): FinishLoggingInAction => ({ type: FINISH_LOGGING_IN });

export function startSamlRequest() {
  return {
    type: START_SAML_REQUEST as typeof START_SAML_REQUEST,
  };
}

export function finishSamlRequest() {
  return {
    type: FINISH_SAML_REQUEST as typeof FINISH_SAML_REQUEST,
  };
}

export const setState =
  (options: Partial<LoadingOptions>): SetStateAction => ({ options, type: SET_STATE });

function _startLoadingLocales(state: LoadingState, action: StartLoadingLocalesAction): LoadingState {
  return { ...state, locales: true };
}

function _finishLoadingLocales(state: LoadingState, action: FinishLoadingLocalesAction): LoadingState {
  return { ...state, locales: false };
}

function _startLoggingIn(state: LoadingState, action: StartLoggingInAction): LoadingState {
  return { ...state, loggingIn: true };
}

function _finishLoggingIn(state: LoadingState, action: FinishLoggingInAction): LoadingState {
  return { ...state, loggingIn: false };
}

function _startSamlRequest(state: LoadingState, action: StartSamlRequestAction): LoadingState {
  return { ...state, samlRequest: true };
}

function _finishSamlRequest(state: LoadingState, action: FinishSamlRequestAction): LoadingState {
  return { ...state, samlRequest: false };
}

function _setState(state: LoadingState, action: SetStateAction): LoadingState {
  return { ...state, state: { ...state.state, ...action.options } };
}

export default function loadingReducer(state: LoadingState, action: LoadingAction): LoadingState {
  switch (action.type) {
    case START_LOADING_LOCALES: return _startLoadingLocales(state, action);
    case FINISH_LOADING_LOCALES: return _finishLoadingLocales(state, action);
    case START_LOGGING_IN: return _startLoggingIn(state, action);
    case FINISH_LOGGING_IN: return _finishLoggingIn(state, action);
    case START_SAML_REQUEST: return _startSamlRequest(state, action);
    case FINISH_SAML_REQUEST: return _finishSamlRequest(state, action);
    case SET_STATE: return _setState(state, action);
    default: return state;
  }
}
