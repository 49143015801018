import * as api from 'gridtools/go/utils';
import { SearchResult } from 'types/go/doc';
import * as go from 'app/utils/go-api';

export { getDownloadLink } from 'app/components/Documents';

export { retrieve } from './retrieve';
export { update } from './update';

export const has = api.has('.' as any, 'go_documents');
export const history = go.history('go_document', 'go_documents');
export const search = go.search<SearchResult>('go_documents', ['go_document']);
