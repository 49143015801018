import * as React from 'react';
import { getShapeInfo } from './telco';
import { ReportNodeProps } from './types';

export const ReportNode: React.FC<ReportNodeProps> = ({ objectType, size, shapeDirection, overrideStyle, selectorWidth, ...props }) => {
  const shape = (objectType && getShapeInfo(objectType)) || {};
  const isSquare = shape.shape === 'square';

  const p: React.SVGProps<any> = { ...props };
  if (shape.color) p.style = { ...p.style, fill: shape.color };
  if (shape.stroke) p.style = { ...p.style, stroke: shape.stroke };
  if (overrideStyle) p.style = { ...p.style, ...overrideStyle };
  if (shape.small) size /= 2;

  const rect = (width: number, height: number) => {
    const { cx: x, cy: y } = props;
    return <rect {...p}
                 x={x - width / 2} y={y - height / 2}
                 width={width} height={height} />;
  };

  if (selectorWidth) {
    return rect(selectorWidth, size);
  }

  if (!shapeDirection && !isSquare) {
    return <circle {...p} r={size / 2} />;
  }

  let width = size, height = size;
  switch (shapeDirection) {
    case 'horizontal': height /= 2; break;
    case 'vertical': width /= 2; break;
    default: height = width = size * 0.8; break;
  }

  return rect(width, height);
};
