import { Reducer } from 'react';
import * as uuid from 'uuid/v4';
import { geojson } from 'gridtools/types';
import { getBBox, merge as mergeGeoJSON } from 'gridtools/utils/geojson';
import {
  DrawColor, DrawingTool as Tool, DrawProperties,
  MapDrawState, MapDrawFinished, MapDrawNotStarted, MapDrawOngoing,
  CreateDocData, CustomToolData
} from 'types';

const CANCEL_DRAWING = 'reducers/map/draw/cancel-drawing';
const BEGIN_DRAWING = 'reducers/map/draw/begin-drawing';
const CHANGE_COLOR = 'reducers/map/draw/change-color';
const CHANGE_TOOL = 'reducers/map/draw/change-tool';
const ADD_DRAWING = 'reducers/map/draw/add-drawing';
const FINISH_DRAWING = 'reducers/map/draw/finish-drawing';
const PUT_DRAWING = 'reducers/map/draw/put-drawing';
const SELECT_DRAWING = 'reducers/map/draw/select-drawing';
const DELETE_DRAWING = 'reducers/map/draw/delete-drawing';
const REPLACE_DRAWING = 'reducers/map/draw/replace-drawing';
const CHANGE_DRAWING_PROPS = 'reducers/map/draw/change-drawing-props';
const EDIT_TEXT_PROPS = 'reducers/map/draw/edit-text-props';
const CLEAR_SELECTION = 'reducers/map/draw/clear-selection';
const DOC_DIALOG_SHOW = 'reducers/map/draw/doc/show-dialog';
const DOC_DIALOG_APPLY = 'reducers/map/draw/doc/add';
const DOC_DIALOG_CANCEL = 'reducers/map/draw/doc/cancel';
const TOGGLE_RO_SELECTION = 'reducers/map/draw/selection/toggle';

export type CancelDrawingAction = ReturnType<typeof cancelDrawing>;
export type BeginDrawingAction = ReturnType<typeof beginDrawing>;
export type ChangeColorAction = ReturnType<typeof changeColor>;
export type ChangeToolAction = ReturnType<typeof changeTool>;
export type AddDrawingAction = ReturnType<typeof addDrawing>;
export type FinishDrawingAction = ReturnType<typeof finishDrawing>;
export type PutDrawingAction = ReturnType<typeof putDrawing>;
export type SelectDrawingAction = ReturnType<typeof selectDrawing>;
export type DeleteDrawingAction = ReturnType<typeof deleteDrawing>;
export type ReplaceDrawingAction = ReturnType<typeof replaceDrawing>;
export type ChangeDrawingPropsAction = ReturnType<typeof changeDrawingProps>;
export type EditTextPropsAction = ReturnType<typeof editTextProps>;
export type ClearSelectionAction = ReturnType<typeof clearSelection>;
export type ToggleSelectableAction = ReturnType<typeof toggleSelectable>;

type ShowDocDialogAction = ReturnType<typeof showDocDialog>;
type ApplyDocDialogAction = ReturnType<typeof applyDocDrawing>;
type CancelDocDialogAction = ReturnType<typeof cancelDocDrawing>;

export type MapDrawAction =
  | CancelDrawingAction
  | BeginDrawingAction
  | ChangeColorAction
  | ChangeToolAction
  | AddDrawingAction
  | FinishDrawingAction
  | PutDrawingAction
  | SelectDrawingAction
  | DeleteDrawingAction
  | ReplaceDrawingAction
  | ChangeDrawingPropsAction
  | EditTextPropsAction
  | ClearSelectionAction
  | ToggleSelectableAction
  | ShowDocDialogAction
  | ApplyDocDialogAction
  | CancelDocDialogAction;

export function cancelDrawing() {
  return {
    type: CANCEL_DRAWING,
  } as const;
}

export function beginDrawing(tool: Tool, color: DrawColor, geometry?: null | geojson.GeoJSON<DrawProperties>,
                             custom: null | CustomToolData = null) {
  return {
    color,
    geometry,
    tool,
    custom,
    type: BEGIN_DRAWING,
  } as const;
}

export function changeColor(color: DrawColor) {
  return {
    color,
    type: CHANGE_COLOR,
  } as const;
}

export function changeDrawingProps(data: Partial<DrawProperties>, keepSelection = false) {
  return {
    type: CHANGE_DRAWING_PROPS,
    data,
    keepSelection,
  } as const;
}

export function changeTool(tool: Tool, custom: null | CustomToolData = null) {
  return {
    tool,
    custom,
    type: CHANGE_TOOL,
  } as const;
}

export function addDrawing(geometry: geojson.Geometry) {
  return {
    geometry,
    type: ADD_DRAWING,
  } as const;
}

export function finishDrawing() {
  return {
    type: FINISH_DRAWING,
  } as const;
}

export function putDrawing(geometry: geojson.GeoJSON<DrawProperties> | null, keepDrawing = false) {
  return { geometry, keepDrawing, type: PUT_DRAWING } as const;
}

function getGeometries(state: MapDrawState) {
  return state.state === 'not-started' ? null : state.geometries;
}

function prepareGeometries(geom: geojson.GeoJSON<DrawProperties>): geojson.FeatureCollection<DrawProperties> {
  const makeFeature = (f: geojson.Feature<DrawProperties>) => f.properties.__key ? f : {
    ...f,
    properties: { ...f.properties, __key: uuid() }
  };
  const toCollection = (g: geojson.Feature<DrawProperties>): geojson.FeatureCollection<DrawProperties> => ({
    type: 'FeatureCollection',
    bbox: g.bbox,
    crs: g.crs,
    features: [ g ]
  });

  switch (geom.type) {
    case 'FeatureCollection':
      return geom.features.every(f => !!f.properties.__key)
        ? geom // change nothing if all features already have keys
        : { ...geom, features: geom.features.map(makeFeature) };
    case 'Feature':
      return toCollection(makeFeature(geom));
    default:
      return toCollection({
        type: 'Feature',
        geometry: geom,
        properties: { __key: uuid() } as DrawProperties,
        bbox: geom.bbox,
        crs: geom.crs
      });
  }
}

export function selectDrawing(feature: geojson.Feature<DrawProperties> | null) {
  return { type: SELECT_DRAWING, feature } as const;
}

export function deleteDrawing(feature: geojson.Feature<DrawProperties>) {
  return { type: DELETE_DRAWING, feature } as const;
}

export function replaceDrawing(feature: geojson.Feature<DrawProperties>) {
  return { type: REPLACE_DRAWING, feature } as const;
}

export function editTextProps(data: DrawProperties | null) {
  return { type: EDIT_TEXT_PROPS, data } as const;
}

export function clearSelection() {
  return { type: CLEAR_SELECTION } as const;
}

export function toggleSelectable(enabled: boolean) {
  return { type: TOGGLE_RO_SELECTION, enabled } as const;
}

export function showDocDialog(geometry: geojson.Geometry) {
  return { type: DOC_DIALOG_SHOW, geometry } as const;
}
export function applyDocDrawing(data: CreateDocData) {
  return { type: DOC_DIALOG_APPLY, data } as const;
}
export function cancelDocDrawing() {
  return { type: DOC_DIALOG_CANCEL } as const;
}

const showDialogReducer: Reducer<MapDrawState, ShowDocDialogAction> = (state, action) => ({
  ...state,
  documentGeometry: action.geometry
});
const applyDocReducer: Reducer<MapDrawState, ApplyDocDialogAction> = (state, action) => {
  const geom = state.documentGeometry;
  return geom
    ? addDrawingFunc(state, geom, action.data)
    : { ...state, documentGeometry: null };
};
const cancelDocReducer = (state: MapDrawState) => ({ ...state, documentGeometry: null });

function cancelDrawingReducer(state: MapDrawState): MapDrawNotStarted {
  return {
    ...state,
    tool: null,
    state: 'not-started',
    selected: null
  };
}

function makeState(state: MapDrawState, tool = state.tool || 'select', custom?: CustomToolData | null): Omit<MapDrawOngoing, 'geometries'> {
  if (tool !== 'custom') {
    custom = null;
  } else {
    if (!custom && state.state === 'ongoing')
      custom = state.toolCustom;

    if (!custom) tool = 'select';
  }

  const selected = tool === 'select' ? state.selected : null;

  return {
    state: 'ongoing',
    documentGeometry: null,
    currentTextEdit: null,
    color: state.color,
    tool,
    toolCustom: custom ?? null,
    selected,
    selectionEnabled: state.selectionEnabled,
  };
}

function beginDrawingReducer(state: MapDrawState, action: BeginDrawingAction): MapDrawOngoing {
  return {
    ...makeState(state, action.tool, action.custom),
    geometries: action.geometry ? prepareGeometries(action.geometry) : null,
    color: action.color,
  };
}

function changeFeature(geom: geojson.FeatureCollection<DrawProperties>, key: string | undefined,
                       change: (f: geojson.Feature<DrawProperties>) => geojson.Feature<DrawProperties> | null) {
  if (!key) return geom;

  const features: geojson.Feature<DrawProperties>[] = [];
  let found = false;
  geom.features.forEach(f => {
    if (f.properties.__key === key) {
      found = true;
      const changed = change(f);
      if (changed) features.push(changed);
    } else {
      features.push(f);
    }
  });

  return found ? { ...geom, features } : geom;
}

function changeSelectedFeature(state: MapDrawState, change: (f: geojson.Feature<DrawProperties>) => geojson.Feature<DrawProperties> | null) {
  let { selected } = state;
  let geometries = getGeometries(state);
  if (geometries && selected) {
    const key = selected.properties.__key;
    geometries = changeFeature(geometries, key, change);
    selected = geometries.features.find(f => f.properties.__key === key) || null;
  }

  return { geometries, selected };
}

function changePropsReducer(state: MapDrawState, { data, keepSelection }: ChangeDrawingPropsAction): MapDrawState {
  const { __key: key, ...props } = data;
  return {
    ...makeState(state),
    ...changeSelectedFeature(state, f => ({ ...f, properties: { ...f.properties, ...props }})),
    ...!keepSelection && { selected: null },
  };
}

function changeColorReducer(state: MapDrawState, { color }: ChangeColorAction): MapDrawOngoing {
  return {
    ...makeState(state),
    ...changeSelectedFeature(state, f => ({ ...f, properties: { ...f.properties, color } })),
    color,
  };
}

function replaceDrawingReducer(state: MapDrawState, action: ReplaceDrawingAction): MapDrawState {
  let { selected } = state;
  if (!selected) return state;

  return {
    ...state,
    ...makeState(state),
    ...changeSelectedFeature(state, () => action.feature),
  };
}

function changeToolReducer(state: MapDrawState, action: ChangeToolAction): MapDrawOngoing {
  return {
    ...makeState(state, action.tool, action.custom),
    geometries: getGeometries(state),
  };
}

function getProperties(properties: DrawProperties) {
  return function(): DrawProperties {
    return { ...properties };
  };
}

function merge(g1: null | geojson.GeoJSON<DrawProperties>, g2: geojson.GeoJSON<DrawProperties>,
               properties: DrawProperties): geojson.GeoJSON<DrawProperties> {
  if (g1 === null) {
    return g2;
  }
  const merged = mergeGeoJSON(g1, g2, getProperties(properties));
  if (merged === null) {
    // should not happen but typescript requires that we handle the null case
    throw new Error('Failed to merge GeoJSON objects.');
  }
  merged.bbox = getBBox(merged);
  merged.crs = g1.crs === undefined ? g2.crs : g1.crs;
  return merged;
}

function addDrawingReducer(state: MapDrawState, action: AddDrawingAction): MapDrawOngoing {
  return addDrawingFunc(state, action.geometry);
}

function addDrawingFunc(state: MapDrawState, geometry: geojson.Geometry, goObjectData?: Record<string, any>): MapDrawOngoing {
  const stateGeometries = getGeometries(state);
  const color = state.color;
  const properties: DrawProperties = { color };
  if (state.tool === 'text') {
    properties.__key = uuid();
    properties.text = 'Text value';
  } else if (state.tool === 'point') {
    properties.point_symbol = 'circle';
  } else if (state.tool === 'go_document' && goObjectData) {
    properties.__key = uuid();
    properties.type = 'go_document';
    properties.goObject = { details: goObjectData };
  } else if (state.tool === 'custom') {
    properties.__key = uuid();
    properties.type = state.tool;
    const customData = state.state === 'ongoing' && state.toolCustom;
    if (customData)
      properties.customData = customData;
  }

  let geometries = merge(stateGeometries, geometry, properties);
  if (geometries.type !== 'FeatureCollection' && geometries.type !== 'Feature') {
    geometries = {
      bbox: geometries.bbox,
      crs: geometries.crs,
      geometry: geometries,
      properties: { ...properties },
      type: 'Feature',
    };
  }

  const prepared = prepareGeometries(geometries);
  const textFeature = state.tool === 'text'
    ? prepared.features.find(f => f.properties.__key === properties.__key) || null
    : null;

  return {
    ...makeState(state),
    geometries: prepared,
    selected: textFeature,
    color,
  };
}

function finishDrawingReducer(state: MapDrawState): MapDrawNotStarted | MapDrawFinished {
  return state.state === 'not-started' || state.geometries === null
    ? { ...state, state: 'not-started', tool: 'select', selected: null }
    : { ...state, geometries: state.geometries, state: 'finished', tool: 'select', selected: null };
}

function putDrawingReducer(state: MapDrawState, action: PutDrawingAction): MapDrawState {
  return action.geometry === null
    ? <MapDrawNotStarted> { ...state, state: 'not-started', selected: null }
    : action.keepDrawing
      ? <MapDrawOngoing> { ...state, geometries: prepareGeometries(action.geometry), state: 'ongoing' }
      : <MapDrawFinished> { ...state, geometries: prepareGeometries(action.geometry), state: 'finished' };
}

function deleteDrawingReducer(state: MapDrawState, action: DeleteDrawingAction): MapDrawState {
  let geometries = getGeometries(state);
  if (!geometries) return state;

  const key = action.feature.properties.__key;
  if (key && geometries) geometries = changeFeature(geometries, key, () => null);
  return {
    ...state,
    ...{ geometries },
    selected: null
  };
}

function selectDrawingReducer(state: MapDrawState, action: SelectDrawingAction): MapDrawState {
  return action.feature === null || state.state === 'not-started'
    ? { ...state, selected: null }
    : { ...state, selected: action.feature };
}

function editTextPropsReducer(state: MapDrawState, action: EditTextPropsAction): MapDrawState {
  return { ...state, currentTextEdit: action.data };
}

function clearSelectionReducer(state: MapDrawState): MapDrawState {
  return { ...state, selected: null };
}

function toggleSelectableReducer(state: MapDrawState, action: ToggleSelectableAction): MapDrawState {
  return { ...state, selectionEnabled: action.enabled };
}

export function drawReducer(state: MapDrawState, action: MapDrawAction): MapDrawState {
  switch (action.type) {
    case CANCEL_DRAWING: return cancelDrawingReducer(state);
    case BEGIN_DRAWING: return beginDrawingReducer(state, action);
    case CHANGE_COLOR: return changeColorReducer(state, action);
    case CHANGE_TOOL: return changeToolReducer(state, action);
    case ADD_DRAWING: return addDrawingReducer(state, action);
    case FINISH_DRAWING: return finishDrawingReducer(state);
    case PUT_DRAWING: return putDrawingReducer(state, action);
    case SELECT_DRAWING: return selectDrawingReducer(state, action);
    case DELETE_DRAWING: return deleteDrawingReducer(state, action);
    case REPLACE_DRAWING: return replaceDrawingReducer(state, action);
    case CHANGE_DRAWING_PROPS: return changePropsReducer(state, action);
    case EDIT_TEXT_PROPS: return editTextPropsReducer(state, action);
    case CLEAR_SELECTION: return clearSelectionReducer(state);
    case TOGGLE_RO_SELECTION: return toggleSelectableReducer(state, action);

    case DOC_DIALOG_SHOW: return showDialogReducer(state, action);
    case DOC_DIALOG_APPLY: return applyDocReducer(state, action);
    case DOC_DIALOG_CANCEL: return cancelDocReducer(state);
    default: return state;
  }
}
