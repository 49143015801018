import { PageFormat, PageOrientation } from 'gridtools/map';
import { PrintState } from 'types';

const SET_FORMAT = 'reducers/print/set-format' as const;
const START_LOADING = 'reducers/print/start-loading' as const;
const STOP_LOADING = 'reducers/print/stop-loading' as const;

export function setPrintFormat(format: PageFormat, orientation: PageOrientation) {
  return {
    type: SET_FORMAT,
    format,
    orientation,
  };
}

export function startLoading() {
  return {
    type: START_LOADING as typeof START_LOADING,
  };
}

export function stopLoading() {
  return {
    type: STOP_LOADING as typeof STOP_LOADING,
  };
}

type SetFormatAction = ReturnType<typeof setPrintFormat>;
type StartLoadingAction = ReturnType<typeof startLoading>;
type StopLoadingAction = ReturnType<typeof stopLoading>;

export type PrintAction =
  | SetFormatAction
  | StartLoadingAction
  | StopLoadingAction;

function setFormatReducer(state: PrintState, action: SetFormatAction) {
  return {
    ...state,
    format: action.format,
    orientation: action.orientation,
  };
}

function startLoadingReducer(state: PrintState): PrintState {
  return {
    ...state,
    loading: true,
  };
}

function stopLoadingReducer(state: PrintState): PrintState {
  return {
    ...state,
    format: null,
    loading: false,
  };
}

export function printReducer(state: PrintState, action: PrintAction): PrintState {
  switch (action.type) {
    case SET_FORMAT: return setFormatReducer(state, action);
    case START_LOADING: return startLoadingReducer(state);
    case STOP_LOADING: return stopLoadingReducer(state);
    default: return state;
  }
}
