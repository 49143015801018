import * as api from 'lib/go/utils';
import { splice_tray } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'splice_trays';

type IDOnly = splice_tray.SpliceTrayIDOnly;
type Full = splice_tray.SpliceTray;
type Debug = splice_tray.SpliceTrayDebug;

type ConnectionIDOnly = splice_tray.ConnectionIDOnly;
type ConnectionFull = splice_tray.Connection;
type ConnectionDebug = splice_tray.Connection;

type CSIDOnly = splice_tray.ChildStructureIDOnly;
type CSFull = splice_tray.ChildStructure;
type CSDebug = splice_tray.ChildStructureDebug;

type PSIDOnly = splice_tray.ParentStructureIDOnly;
type PSFull = splice_tray.ParentStructure;
type PSDebug = splice_tray.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<splice_tray.Stats>(namespace, resource);
