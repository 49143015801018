import * as api from 'lib/go/utils';
import { fiber } from 'lib/types/go/telco';

import { NAMESPACE, RESOURCE } from './constants';

export { endNode } from './end-node';
export { startNode } from './start-node';

type IDOnly = fiber.FiberIDOnly;
type Full = fiber.Fiber;
type Debug = fiber.FiberDebug;

type CSIDOnly = fiber.ChildStructureIDOnly;
type CSFull = fiber.ChildStructure;
type CSDebug = fiber.ChildStructureDebug;

type PSIDOnly = fiber.ParentStructureIDOnly;
type PSFull = fiber.ParentStructure;
type PSDebug = fiber.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(NAMESPACE, RESOURCE);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(NAMESPACE, RESOURCE);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(NAMESPACE, RESOURCE);
export const stats = api.stats<fiber.Stats>(NAMESPACE, RESOURCE);
