import {
  MapLayersBackgroundState,
  MapLayersCustomState,
  MapLayersGOFilterState,
  MapLayersGOState,
  MapLayersState
} from 'types';
import { infoReducer, MapLoadInfoAction } from './info';

// actions
const TOGGLE_BACKGROUND = 'store/map/layers/toggle-background';
const TOGGLE_CUSTOM = 'store/map/layers/toggle-custom';
const TOGGLE_GO = 'store/map/layers/toggle-go';
const RESET_WMS_CACHE = 'store/map/layers/reset-cache';
const TOGGLE_GO_FILTERS = 'store/map/layers/toggle-go-filters';
const TOGGLE_CUSTOM_FILTERS = 'store/map/layers/toggle-custom-filters';

export type ToggleBackgroundAction = ReturnType<typeof toggleBackground>;
export type ToggleCustomAction = ReturnType<typeof toggleCustom>;
export type ToggleGOAction = ReturnType<typeof toggleGo>;
export type ResetWmsCacheAction = ReturnType<typeof resetWmsCache>;
export type ToggleGOFilterAction = ReturnType<typeof toggleGoFilter>;
export type ToggleCustomFilterAction = ReturnType<typeof toggleCustomFilter>;

export type MapLayersAction =
  | ToggleBackgroundAction
  | ToggleCustomAction
  | ToggleGOAction
  | ToggleGOFilterAction
  | ToggleCustomFilterAction
  | ResetWmsCacheAction
  | MapLoadInfoAction;

// action creators
export function toggleBackground(background: Partial<MapLayersBackgroundState>) {
  return {
    background,
    type: TOGGLE_BACKGROUND as typeof TOGGLE_BACKGROUND,
  };
}

export function toggleCustom(custom: MapLayersCustomState) {
  return {
    custom,
    type: TOGGLE_CUSTOM as typeof TOGGLE_CUSTOM,
  };
}

export function toggleGo(layers: Partial<MapLayersGOState>) {
  return {
    layers,
    type: TOGGLE_GO as typeof TOGGLE_GO
  };
}

export const resetWmsCache = () => <const>{ type: RESET_WMS_CACHE, cacheBoost: Date.now() };

export function toggleGoFilter(filter: Partial<MapLayersGOFilterState>) {
  return {
    filter,
    type: TOGGLE_GO_FILTERS as typeof TOGGLE_GO_FILTERS
  };
}

export function toggleCustomFilter(customFilter: MapLayersCustomState) {
  return {
    customFilter,
    type: TOGGLE_CUSTOM_FILTERS as typeof TOGGLE_CUSTOM_FILTERS
  };
}

// reducers
function _toggleBackground(state: MapLayersState, action: ToggleBackgroundAction): MapLayersState {
  return {
    ...state,
    background: {
      ...state.background,
      ...action.background,
    },
  };
}

function _toggleCustom(state: MapLayersState, action: ToggleCustomAction): MapLayersState {
  return {
    ...state,
    custom: {
      ...state.custom,
      ...action.custom,
    },
  };
}

function _toggleGo(state: MapLayersState, action: ToggleGOAction): MapLayersState {
  return {
    ...state,
    go: {
      ...state.go,
      ...action.layers,
      ...FEATURES.sketching && FEATURES.sketching.enabled
        ? null
        : { sketch: false }
    },
  };
}

const _resetWmsCache = (state: MapLayersState, action: ResetWmsCacheAction): MapLayersState => ({
  ...state,
  urlCacheBoost: action.cacheBoost
});

function _toggleGoFilter(state: MapLayersState, action: ToggleGOFilterAction): MapLayersState {
  return {
    ...state,
    goFilter: {
      ...state.goFilter,
      ...action.filter,
    },
  };
}

function _toggleCustomFilter(state: MapLayersState, action: ToggleCustomFilterAction): MapLayersState {
  return {
    ...state,
    customFilter: {
      ...state.customFilter,
      ...action.customFilter,
    },
  };
}

export function layersReducer(state: MapLayersState, action: MapLayersAction): MapLayersState {
  switch (action.type) {
    case TOGGLE_BACKGROUND: return _toggleBackground(state, action);
    case TOGGLE_CUSTOM: return _toggleCustom(state, action);
    case TOGGLE_GO: return _toggleGo(state, action);
    case RESET_WMS_CACHE: return _resetWmsCache(state, action);
    case TOGGLE_GO_FILTERS: return _toggleGoFilter(state, action);
    case TOGGLE_CUSTOM_FILTERS: return _toggleCustomFilter(state, action);
    default: return infoReducer(state, action);
  }
}
