import { Message, MessagesState } from 'types';

type PopAction = ReturnType<typeof pop>;
type PushAction = ReturnType<typeof push>;

export type MessagesAction =
  | PopAction
  | PushAction;

const POP = 'store/message/pop';
const PUSH = 'store/message/push';

export function pop() {
  return { type: POP as typeof POP };
}

export function push(message: Message) {
  return { message, type: PUSH as typeof PUSH };
}

export function popReducer(state: MessagesState, action: PopAction): MessagesState {
  return state.slice(1);
}

function pushReducer(state: MessagesState, action: PushAction): MessagesState {
  return [...state, action.message];
}

export function messagesReducer(state: MessagesState, action: MessagesAction): MessagesState {
  switch (action.type) {
    case POP: return popReducer(state, action);
    case PUSH: return pushReducer(state, action);
    default: return state;
  }
}
