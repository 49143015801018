import * as api from 'lib/go/utils';
import { building } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'buildings';

type IDOnly = building.BuildingIDOnly;
type Full = building.Building;
type Debug = building.BuildingDebug;

type CSIDOnly = building.ChildStructureIDOnly;
type CSFull = building.ChildStructure;
type CSDebug = building.ChildStructureDebug;

type PSIDOnly = building.ParentStructureIDOnly;
type PSFull = building.ParentStructure;
type PSDebug = building.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<building.Stats>(namespace, resource);
