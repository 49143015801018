import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'gridtools/go/utils';
import { SearchError } from 'gridtools/types/go/error';
import { Request } from 'gridtools/types/go/request';
import { SearchOptions, Search } from 'gridtools/types/go/telco/search';
import { Params } from 'gridtools/utils/http';

const error = (err: AxiosError) => catchError<SearchError>(err);
const success = <R>(response: AxiosResponse) => setSuccess<R[]>(response);

export function search<Result = Search>(path: string, scope?: string[]) {
  return function search(baseUrl: string, accessToken: string) {
    return (opts: SearchOptions): Request<Result[], SearchError> => {
      const source = axios.CancelToken.source();
      const cancelToken = source.token;
      const cancel = source.cancel;

      const params = new Params();
      params.set('access_token', accessToken);
      params.set('query', opts.query);
      const searchScope = opts.scope && opts.scope.length > 0 ? opts.scope : scope;
      if (searchScope && searchScope.length > 0) {
        params.set('scope', searchScope.join());
      }
      if (opts.limit !== undefined) {
        params.set('limit', opts.limit.toString());
      }
      if (opts.version !== undefined) {
        params.set('version', opts.version);
      }
      const url = `${baseUrl}/${path}/search?${params.toString()}`;
      const request = axios.get(url, { cancelToken }).then(r => success<Result>(r)).catch(error);

      return { cancel, request };
    };
  };
}
