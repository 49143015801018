import axios, { AxiosError, AxiosResponse } from 'axios';
import { api, error as _error, request as _request, stats as _stats } from 'lib/types/go';
import { Params } from 'lib/utils/http';

import { catchError, setSuccess } from './response';

function getParams(accessToken: string, opts?: api.StatsOptions): string {
  const params = new Params();
  params.set('access_token', accessToken);
  if (opts !== undefined) {
    if (opts.date !== undefined) {
      params.set('date', opts.date); 
    }
    if (opts.filter !== undefined) {
      params.set('filter', JSON.stringify(opts.filter)); 
    }
    if (opts.group_by !== undefined && opts.group_by.length !== 0) {
      params.set('group_by', opts.group_by.join(',')); 
    }
    if (opts.version !== undefined) {
      params.set('version', opts.version); 
    }
  }
  return params.toString();
}

export function stats<ReturnValue extends _stats.Stats>(namespace: 'dk' | 'telco', resource: string) {
  const error = (err: AxiosError) => catchError<_error.ObjectError>(err);
  const success = (response: AxiosResponse) => setSuccess<ReturnValue>(response);
  return (baseURL: string, accessToken: string) => {
    return (opts?: api.StatsOptions): _request.Request<ReturnValue, _error.ObjectError> => {
      const source = axios.CancelToken.source();
      const cancelToken = source.token;
      const cancel = source.cancel;
      const params = getParams(accessToken, opts);
      const url = `${baseURL}/${namespace}/${resource}/stats?${params}`;
      const request = axios.get(url, { cancelToken }).then(success).catch(error);
      return { cancel, request };
    };
  };
}
