import * as React from 'react';

import { AppContext } from './interfaces';

const context = React.createContext<null | AppContext>(null);

export const AppContextConsumer = context.Consumer;
export const AppContextProvider = context.Provider;

export const useAppContext = () => React.useContext(context);
