import { AuthLoginResponse, AuthState } from 'types';

// actions
const LOGIN = 'store/auth/login';
const LOGOUT = 'store/auth/logout';
const REMOVE_LOGIN_RESPONSE = 'store/auth/remove-login-response';
const SET_LOGIN_RESPONSE = 'store/auth/set-login-response';
const SET_ORTOFOTO_TOKEN = 'store/auth/set-kortforsyningen-token';
const UNSET_ORTOFOTO_TOKEN = 'store/auth/unset-kortforsyningen-token';

// action types
export type LoginAction = { type: typeof LOGIN; id: number, token: string; username: string; };
export type LogoutAction = { type: typeof LOGOUT; };
export type RemoveLoginResponseAction = { type: typeof REMOVE_LOGIN_RESPONSE; };
export type SetLoginResponseAction = { type: typeof SET_LOGIN_RESPONSE; response: AuthLoginResponse; };
export type SetKortforsyningenToken = { type: typeof SET_ORTOFOTO_TOKEN; token: string; };
export type UnsetKortforsyningenToken = { type: typeof UNSET_ORTOFOTO_TOKEN; };

export type AuthAction =
  | LoginAction
  | LogoutAction
  | RemoveLoginResponseAction
  | SetLoginResponseAction
  | SetKortforsyningenToken
  | UnsetKortforsyningenToken;

// action creators
export const login = (id: number, token: string, username: string): LoginAction => ({ id, token, username, type: LOGIN });
export const logout = (): LogoutAction => ({ type: LOGOUT });
export const removeLoginResponse = (): RemoveLoginResponseAction => ({ type: REMOVE_LOGIN_RESPONSE });
export const setLoginResponse = (response: AuthLoginResponse): SetLoginResponseAction => ({ response, type: SET_LOGIN_RESPONSE });
export const setKortforsyningenToken = (token: string): SetKortforsyningenToken => ({ token, type: SET_ORTOFOTO_TOKEN });
export const unsetKortforsyningenToken = (): UnsetKortforsyningenToken => ({ type: UNSET_ORTOFOTO_TOKEN });

// reducers
const _login = (state: AuthState, action: LoginAction): AuthState => ({
  ...state,
  user: {
    id: action.id,
    token: action.token,
    username: action.username
  }
});

const _logout = (state: AuthState, action: LogoutAction): AuthState => ({ ...state, user: null });

const _removeLoginResponse = (state: AuthState, action: RemoveLoginResponseAction): AuthState => ({ ...state, loginResponse: null });

const _setLoginResponse = (state: AuthState, action: SetLoginResponseAction): AuthState => ({ ...state, loginResponse: action.response });

const _setKortforsyningenToken =
  (state: AuthState, action: SetKortforsyningenToken): AuthState => ({ ...state, kortforsyningen: action.token });

const _unsetKortforsyningenToken
  = (state: AuthState, action: UnsetKortforsyningenToken): AuthState => ({ ...state, kortforsyningen: null });

export default function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case LOGIN: return _login(state, action);
    case LOGOUT: return _logout(state, action);
    case REMOVE_LOGIN_RESPONSE: return _removeLoginResponse(state, action);
    case SET_LOGIN_RESPONSE: return _setLoginResponse(state, action);
    case SET_ORTOFOTO_TOKEN: return _setKortforsyningenToken(state, action);
    case UNSET_ORTOFOTO_TOKEN: return _unsetKortforsyningenToken(state, action);
    default: return state;
  }
}
