import { GOState } from 'types';

import goPropertiesReducer, { GOPropertiesAction } from './properties';

export type GOAction =
  | GOPropertiesAction;

export default function goReducer(state: GOState, action: GOAction): GOState {
  return {
    properties: goPropertiesReducer(state.properties, action as GOPropertiesAction),
  };
}
