import * as api from 'lib/go/utils';
import { building_unit } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'building_units';

type IDOnly = building_unit.BuildingUnitIDOnly;
type Full = building_unit.BuildingUnit;
type Debug = building_unit.BuildingUnitDebug;

type CSIDOnly = building_unit.ChildStructureIDOnly;
type CSFull = building_unit.ChildStructure;
type CSDebug = building_unit.ChildStructureDebug;

type PSIDOnly = building_unit.ParentStructureIDOnly;
type PSFull = building_unit.ParentStructure;
type PSDebug = building_unit.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<building_unit.Stats>(namespace, resource);
