import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils';
import { error as _error, request as _request } from 'lib/types/go';
import userCompany from 'lib/go/users/company';

const error = (err: AxiosError) => catchError<_error.LoginError>(err);

const getCompanyNameById = (id: string, token: string) => {
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const cancel = source.cancel;
  const url = `${API_URLS.gridoptimizer}/company/${id}?access_token=${token}`;
  const headers = { 'Content-Type': 'application/json' };
  // if company id is set to 0 or null
  if (id === '0' || !id) {
    // show all workorders
    userCompany.short = 'admin';
    Object.freeze(userCompany);
  } else if (id === '-1') {
    userCompany.short = 'lightaccess';
  } else {
    axios.get(url, { cancelToken, headers }).then((response) => {
      if (response.status === 200) {
        const { data } = response;
        // return company short name
        userCompany.short = data.short;
        userCompany.long = data.long;
        userCompany.color = getCompanyColor(data.short);
        Object.freeze(userCompany);
      }
    }).catch(error);
  }
}

const getCompanyColor = (name: string) => {
  let color = '';
  switch(name) {
    case 'gran' :
      color = '#fed400';
      break;
    case 'wlen' :
      color = '#fe9800';
      break;
    case 'vikt' :
      color = '#fe5d00';
      break;
    case 'sknu' :
      color = '#fe000c';
      break;
    case 'kimh' :
      color = '#fe00a1';
      break;
    case 'rero' :
      color = '#e100fe';
      break;
    case 'otek' :
      color = '#8001ff';
      break;
    case 'idom' :
      color = '#1201ff';
      break;
    case 'mahu' :
      color = '#019aff';
      break;
    case 'vift' :
      color = '#01fff3';
      break;
    case 'mhen' :
      color = '#cba900';
      break;
    case 'telk' :
      color = '#cc7700';
      break;
    case 'nord' :
      color = '#cb4300';
      break;
    case 'lang' :
      color = '#cc000a';
      break;
    case 'fibt' :
      color = '#cc0085';
      break;
    case 'note' :
      color = '#cc0085';
      break;
    case 'buke' :
      color = '#b400cc';
      break;
    case 'fibf' :
      color = '#6600cc';
      break;
    case 'dkng' :
      color = '#0e00cc';
      break;
    case 'dajo' :
      color = '#007ecc';
      break;
    case 'jeka' :
      color = '#00ccc2';
      break;
  }
  return color;
}

export const getUserCompany = (userId: number, token: string) => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const url = `${API_URLS.gridoptimizer}/users/${userId}?access_token=${token}`;
    const headers = { 'Content-Type': 'application/json' };
    axios.get(url, { cancelToken, headers }).then((response) => {
      if (response.status === 200) {
        const { data } = response;
        const { company } = data;
        // get companyName from companyId
        const companyName = getCompanyNameById(company, token);
      }
    }).catch(error);
}
