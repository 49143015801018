import turfDestination from '@turf/destination';
import { geojson } from 'gridtools/types';
import { changeCoordinateProjection } from 'gridtools/utils/geojson';

const translatePoint = (origin: [number, number, number?], distance: number, bearing: number): null | geojson.Coordinates => {
  const translated = turfDestination([origin[0], origin[1]], distance, bearing, { units: 'meters' });
  if (translated === null) {
    return null;
  }
  const point = translated.geometry as geojson.Point;
  return changeCoordinateProjection(point.coordinates, 'EPSG:4326', 'EPSG:3857');
};

export function point2square(point: geojson.Coordinates): null | geojson.Polygon {
  const radius = 200;
  const origin = changeCoordinateProjection(point, 'EPSG:3857', 'EPSG:4326');
  const topLeft = translatePoint(origin, radius, 135);
  const topRight = translatePoint(origin, radius, 45);
  const bottomLeft = translatePoint(origin, radius, 225);
  const bottomRight = translatePoint(origin, radius, 315);
  if (topLeft !== null && topRight !== null && bottomLeft !== null && bottomRight !== null) {
    return {
      coordinates: [
        [
          topLeft,
          topRight,
          bottomRight,
          bottomLeft,
          topLeft,
        ],
      ],
      type: 'Polygon',
    };
  }
  return null;
}
