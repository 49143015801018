import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils';
import { SearchError } from 'lib/types/go/error';
import { Request } from 'lib/types/go/request';
import { search as _search } from 'lib/types/go/telco';
import { Params } from 'lib/utils/http';

const error = (err: AxiosError) => catchError<SearchError>(err);
const success = (response: AxiosResponse) => setSuccess<_search.Search[]>(response);

export function search(baseUrl: string, accessToken: string) {
  return (opts: _search.SearchOptions): Request<_search.Search[], SearchError> => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const params = new Params();
    params.set('access_token', accessToken);
    params.set('query', opts.query);
    if (opts.limit !== undefined) {
      params.set('limit', opts.limit.toString()); 
    }
    if (opts.scope !== undefined && opts.scope.length !== 0) {
      params.set('scope', opts.scope.join(',')); 
    }
    if (opts.version !== undefined) {
      params.set('version', opts.version); 
    }
    const url = `${baseUrl}/telco/search?${params.toString()}`;
    const request = axios.get(url, { cancelToken }).then(success).catch(error);
    return { cancel, request };
  };
}
