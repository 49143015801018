import * as api from 'lib/go/utils';
import { optical_path } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'optical_paths';

type IDOnly = optical_path.OpticalPathIDOnly;
type Full = optical_path.OpticalPath;
type Debug = optical_path.OpticalPathDebug;

type CSIDOnly = optical_path.ChildStructureIDOnly;
type CSFull = optical_path.ChildStructure;
type CSDebug = optical_path.ChildStructureDebug;

type PSIDOnly = optical_path.ParentStructureIDOnly;
type PSFull = optical_path.ParentStructure;
type PSDebug = optical_path.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<optical_path.Stats>(namespace, resource);
