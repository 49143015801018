import * as React from 'react';
import { ExcludeKeys } from 'gridtools/types/utils';
import { STYLES, SVG_LINE } from './constants';
import { _Dimensions } from './types';

const positions = {
  cols: [0, 177, 190, 260, 330, 500, 610, 945],
  rows: [0, 27, 54, 81]
};

export type RectCoords = { x1: number, y1: number, x2: number, y2: number };

type FooterCellProps = {
  r: number;
  c: number;
  rowspan?: number, colspan?: number;

  contentRender?: (coords: RectCoords) => JSX.Element | null;

  dimensions: _Dimensions;
};

const FooterCell: React.FC<FooterCellProps> = p => {
  const {
    r, c, rowspan = 1, colspan = 1, contentRender,
    dimensions: {
      height: H,
      width: W,
      footerHeight
    }
  } = p;

  const x1 = positions.cols[c];
  const x2 = positions.cols[c + colspan] || W;

  const ycoord = (y: number) => isNaN(y) ? H : H - footerHeight + y;

  const y1 = ycoord(positions.rows[r]);
  const y2 = ycoord(positions.rows[r + rowspan]);

  const lineAttrs = {
    ...SVG_LINE,
    x1, x2, y1, y2
  };

  return <React.Fragment>
    {
      [
        { ...lineAttrs, y2: y1 },
        x1 > 0 && { ...lineAttrs, x2: x1 },
        y2 < H && { ...lineAttrs, y1: y2 },
        x2 < H && { ...lineAttrs, x1: x2 }
      ].map((c, i) => c && <line key={i} {...c} />)
    }
    {contentRender && contentRender(lineAttrs)}
  </React.Fragment>;
};

export const withReportSize = (size: _Dimensions) => {
  type Props = ExcludeKeys<FooterCellProps, 'dimensions'>;
  const Cell: React.FC<Props> = p => <FooterCell
    {...p}
    dimensions={size} />;
  return Cell;
};

export const textContentRender = (label: string, value?: string, textProps?: any) => (rect: RectCoords) => {
  const { normal, small } = STYLES.text;

  return (
    <React.Fragment>
      {label && <text x={rect.x1 + 3}
                      y={rect.y1 + 3 + small.fontSize}
                      textAnchor='left'
                      style={small}>
        {label.toUpperCase()}
      </text>}

      {value && <text x={(rect.x1 + rect.x2) / 2}
                      y={rect.y2 - 5}
                      textAnchor='middle'
                      style={normal}
                      {...textProps}>
        {value}
      </text>}
    </React.Fragment>
  );
};

export const imageContentRender = (image: string, type: 'left' | 'right' = 'left') => (pos: RectCoords) => {
  const dx = type === 'left' ? 0 : 3;
  const x = pos.x1 + dx;
  const y = pos.y1 + 1;

  const props = {
    href: image,
    x,
    y,
    width: pos.x2 - 1 - x - dx,
    height: pos.y2 - 1 - y
  };

  return <image {...props} />;
};
