import { Action, ActionsState } from 'types';

type DisableAction = ReturnType<typeof disable>;
type EnableAction = ReturnType<typeof enable>;

type SelectCrsAction = ReturnType<typeof selectCrs>;
type ToggleGotoMapAction = ReturnType<typeof toggleGotoMap>;

export type ActionsAction =
  | DisableAction
  | EnableAction
  | SelectCrsAction
  | ToggleGotoMapAction;

const DISABLE = 'store/ui/actions/disable';
const ENABLE = 'store/ui/actions/enable';
const SELECT_CRS = 'store/ui/actions/select_crs';
const TOGGLE_GOTO_MAP = 'store/ui/actions/toggle_goto_map';

export function disable(action: Action) {
  return { action, type: DISABLE as typeof DISABLE };
}

export function enable(action: Action) {
  return { action, type: ENABLE as typeof ENABLE };
}

export function selectCrs(crs: string) {
  return { type: SELECT_CRS as typeof SELECT_CRS, crs };
}

export function toggleGotoMap(gotoEnabled: boolean) {
  return { type: TOGGLE_GOTO_MAP as typeof TOGGLE_GOTO_MAP, enabled: gotoEnabled };
}

function disableReducer(state: ActionsState, action: DisableAction): ActionsState {
  switch (action.action) {
    case Action.DroneSelect: return { ...state, droneSelect: false };
    case Action.MapLayers: return { ...state, mapLayers: false };
    case Action.Measure: return { ...state, measure: false };
    case Action.Print: return { ...state, print: false };
  }
}

function enableReducer(state: ActionsState, action: EnableAction): ActionsState {
  switch (action.action) {
    case Action.DroneSelect: return { ...state, droneSelect: true, measure: false, print: false };
    case Action.MapLayers: return { ...state, mapLayers: true };
    case Action.Measure: return { ...state, droneSelect: false, measure: true, print: false };
    case Action.Print: return { ...state, print: true };
  }
}

function selectCrsReducer(state: ActionsState, { crs }: SelectCrsAction): ActionsState {
  return { ...state, crs };
}

function toggleGotoMapReducer(state: ActionsState, { enabled }: ToggleGotoMapAction): ActionsState {
  return { ...state, gotoMapOnOpen: enabled };
}

export function actionsReducer(state: ActionsState, action: ActionsAction): ActionsState {
  switch (action.type) {
    case DISABLE: return disableReducer(state, action);
    case ENABLE: return enableReducer(state, action);
    case SELECT_CRS: return selectCrsReducer(state, action);
    case TOGGLE_GOTO_MAP: return toggleGotoMapReducer(state, action);
    default: return state;
  }
}
