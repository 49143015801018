import * as api from 'lib/go/utils';
import { fiber_cable } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'fiber_cables';

type IDOnly = fiber_cable.FiberCableIDOnly;
type Full = fiber_cable.FiberCable;
type Debug = fiber_cable.FiberCableDebug;

type CSIDOnly = fiber_cable.ChildStructureIDOnly;
type CSFull = fiber_cable.ChildStructure;
type CSDebug = fiber_cable.ChildStructureDebug;

type OPSIDOnly = fiber_cable.OrderedParentStructureIDOnly;
type OPSFull = fiber_cable.OrderedParentStructure;
type OPSDebug = fiber_cable.OrderedParentStructureDebug;

type PSIDOnly = fiber_cable.ParentStructureIDOnly;
type PSFull = fiber_cable.ParentStructure;
type PSDebug = fiber_cable.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const orderedParentStructure = api.orderedParentStructure<OPSIDOnly, OPSFull, OPSDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<fiber_cable.Stats>(namespace, resource);
