import * as React from 'react';
import { DELTA_Y, INIT_X, INIT_Y, ITEM_RADIUS as radius, LINE_MARGIN, STYLES, SVG_LINE, SVG_SHAPE } from './constants';
import { DataItem } from './types';
import { ReportNode } from './ReportNode';
import { prepareArrays } from './utils';

type ReportDataProps = {
  items: DataItem[];

  reportWidth: number;

  labelFontSize?: keyof typeof STYLES.text;
};

const getMiddleLinePosition = (y: number) => y + DELTA_Y.top;

type Coords = { x: number, y: number };

class CoordHelper {
  private readonly positions: number[];

  readonly xmin: number;
  readonly xmax: number;
  readonly rowSize: number;

  constructor(private readonly width: number) {
    this.positions = [
      INIT_X,
      width / 2,
      width - INIT_X
    ];

    this.xmin = LINE_MARGIN;
    this.xmax = width - LINE_MARGIN;

    this.rowSize = this.positions.length;
  }

  getCoords(i: number): Coords {
    const rowSize = this.positions.length;
    const dy = DELTA_Y.top + DELTA_Y.down;

    return {
      x: this.positions[i % rowSize],
      y: INIT_Y + Math.floor(i / rowSize) * dy
    };
  }

  getLinkTextCoords(i: number): Coords {
    const a = this.getCoords(i),
      b = this.getCoords(i + 1);

    return {
      x: (a.x + b.x) / 2,
      y: a.y === b.y
        ? a.y
        : getMiddleLinePosition(a.y)
    };
  }
}

class ReportData extends React.PureComponent<ReportDataProps> {
  private readonly coords: CoordHelper;

  constructor(props: ReportDataProps) {
    super(props);
    // props.items.splice(7, 0, props.items[3])

    this.coords = new CoordHelper(props.reportWidth);
  }

  render() {
    const { items } = this.props;
    const max = items.length - 1;

    const { push, all } = prepareArrays('passthrough', 'halo', 'link', 'node');

    items.forEach((item, i) => {
      const position = this.coords.getCoords(i);

      if (i < max) {
        push('halo', this.renderLink(item, position, i, true));
        push('link', this.renderLink(item, position, i, false));
      }

      const passthrough = item.type === 'passthrough' || item.type === 'rn_container';
      push(passthrough ? 'passthrough' : 'node',
        ReportData.renderItem(item, position, 'shape-' + i),
        ...this.renderText(item, position, i));
    });
    return (
      <React.Fragment>
        {all()}
      </React.Fragment>
    );
  }

  // find nearest circles before and after items[index], and returns total length between them
  private getCirclesDistance(index: number) {
    const { items } = this.props;
    if (items[index].type === 'item') return 0;

    let length = 0;
    for (let i = index - 1; i >= 0; i--) {
      length += items[i].length || 0;
      if (items[i].type === 'item') break;
    }
    for (let i = index; i < items.length; i++) {
      if (items[i].type === 'item') break;
      length += items[i].length || 0;
    }
    return length;
  }

  private renderLink(item: DataItem, curr: Coords, index: number, halo: boolean) {
    const next = this.coords.getCoords(index + 1);

    const points = [
      [curr.x, curr.y].join(),
      [next.x, next.y].join()];

    const middle = curr.y === next.y ? null : getMiddleLinePosition(curr.y);
    if (middle) {
      const { xmin, xmax } = this.coords;
      points.splice(1, 0,
        [xmax, curr.y].join(),
        [xmax, middle].join(),
        [xmin, middle].join(),
        [xmin, next.y].join());
    }

    const props: React.SVGProps<SVGPolylineElement> = halo ? {
      strokeWidth: 3,
      stroke: '#fff',
    } : {
      strokeWidth: 1,
    };

    return (
      <polyline key={`link-${halo}-${index}`}
                points={points.join(' ')}
                strokeDasharray={item.style === 'dashed' ? '5 10' : undefined}
                data-middle={halo ? null : middle}
                {...SVG_LINE}
                {...props} />
    );
  }

  private renderText(item: DataItem, { x, y }: Coords, index: number) {
    const { labelFontSize = 'normal' } = this.props;
    const textProps = {
      x,
      textAnchor: 'middle',
      style: STYLES.text[labelFontSize]
    };
    const key = () => `txt${index}-${texts.length}`;
    const lineHeight = textProps.style.fontSize * 1.3;

    const texts: JSX.Element[] = [];
    const appendText = (text: string | undefined, baseline: number) => {
      if (text) {
        texts.push(<text
          {...textProps}
          y={baseline}
          key={key()}>
          {text}
        </text>);
      }
    };

    item.label
      .filter(s => s)
      .forEach((text, row) => {
        const position = y + radius + (row + 1) * lineHeight;
        appendText(text, position);
      });

    const textOverShape = (above?: string, below?: string) => {
      const baseline = y - radius - lineHeight / 2;

      appendText(below && above, baseline - lineHeight);
      appendText(below || above, baseline);
    };

    const next = this.props.items[index + 1];
    const nextType = (next && next.type) || 'item';
    const itemType = item.type || 'item';

    if (itemType === 'passthrough') {
      textOverShape(item.above, item.below);
    } else if (itemType === 'rn_container') {
      if (item.getLineText) {
        const length = this.getCirclesDistance(index);
        textOverShape(item.getLineText(length));
      }
    } else if (itemType === 'item' && nextType === 'item') {
      const textPos = this.coords.getLinkTextCoords(index);
      textProps.x = textPos.x;

      appendText(item.above, textPos.y - lineHeight / 2);
      appendText(item.below, textPos.y + lineHeight);
    }

    return texts;
  }

  private static renderItem({ objectType }: DataItem, { x, y }: Coords, key: string) {
    return <ReportNode key={key} {...SVG_SHAPE}
                       objectType={objectType}
                       cx={x} cy={y} size={radius * 2} />;
  }
}

export default ReportData;
