import { EditState } from 'types';

const EDIT_KEY = 'reducers/edit/edit-key' as const;

export const editKey = (key: string) => ({ type: EDIT_KEY, key });

type EditKeyAction = ReturnType<typeof editKey>;
export type EditAction =
  | EditKeyAction;

export default function editReducer(state: EditState, action: EditAction): EditState {
  switch (action.type) {
    case EDIT_KEY: return { ...state, editorKey: action.key };
    default: return state;
  }
}
