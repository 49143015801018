import * as api from 'lib/go/utils';
import { route_node } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'route_nodes';

type IDOnly = route_node.RouteNodeIDOnly;
type Full = route_node.RouteNode;
type Debug = route_node.RouteNodeDebug;

type ConnectionIDOnly = route_node.ConnectionIDOnly;
type ConnectionFull = route_node.Connection;
type ConnectionDebug = route_node.Connection;

type CSIDOnly = route_node.ChildStructureIDOnly;
type CSFull = route_node.ChildStructure;
type CSDebug = route_node.ChildStructureDebug;

type PSIDOnly = route_node.ParentStructureIDOnly;
type PSFull = route_node.ParentStructure;
type PSDebug = route_node.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<route_node.Stats>(namespace, resource);
