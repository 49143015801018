import { Theme } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const { custom_colors } = FEATURES;
const { primary } = custom_colors || {};
const { contrastText, dark, light, main } = primary || {};

export const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: contrastText || '#ffffff',
      dark: dark || '#0069c0',
      light: light || '#64b5f6',
      main: main || '#2196f3',
    },
    secondary: {
      contrastText: '#000000',
      dark: '#c56200',
      light: '#ffc246',
      main: '#ff9100',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

if (IS_DEVELOPMENT) console.log(theme);
