import { SearchState } from 'types';

// actions
const SET_RESULTS = 'store/ui/header/search/set-results';

export type SetResultsAction = { type: typeof SET_RESULTS; results: SearchState; };

export type SearchAction =
  | SetResultsAction;

// action creators
export function setResults(results: SearchState): SetResultsAction {
  return { results, type: SET_RESULTS };
}

// reducers
function _setResults(state: SearchState, results: SearchState): SearchState {
  return results;
}

export default function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case SET_RESULTS: return _setResults(state, action.results);
    default: return state;
  }
}
