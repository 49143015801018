import axios, { AxiosError, AxiosResponse } from 'axios';
import { error as _error, request } from 'lib/types/go';

export function catchError<Error extends _error.BaseError>(
  error: AxiosError
): Promise<request.CancelledResponse | request.ErrorResponse<Error>> {
  if (error.response !== undefined && error.response.data !== undefined) {
    const responseError: request.ErrorResponse<Error> = {
      error: error.response.data.error,
      type: 'error',
    };
    return Promise.resolve(responseError);
  } if (axios.isCancel(error)) {
    const cancelled: request.CancelledResponse = { type: 'cancelled' };
    return Promise.resolve(cancelled);
  }
  return Promise.reject(error);
}

export function setSuccess<Success>(response: AxiosResponse<Success>): request.SuccessResponse<Success> {
  return {
    result: response.data,
    type: 'success',
  };
}
