import { MapLocationState } from 'types/store/map';

const CHANGE = 'store/map/location/change';

export type GeolocationChangeAction = ReturnType<typeof changeGeolocation>;

export type MapGeolocationAction =
  | GeolocationChangeAction;

export const changeGeolocation = (position: [number, number], clicked: boolean) => ({
  type: CHANGE,
  position,
  clicked,
} as const);

const _changeGeolocation = (state: MapLocationState, action: GeolocationChangeAction): MapLocationState => ({
  position: action.position,
  visible: state.visible || action.clicked,
});

export default function reducer(state: MapLocationState, action: MapGeolocationAction) {
  switch (action.type) {
    case CHANGE: return _changeGeolocation(state, action);
    default: return state;
  }
}
