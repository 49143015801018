import { withStyles } from '@material-ui/core';
import { analytics } from 'app/containers/Analytics';
import { setState } from 'app/reducers/loading';
import { login } from 'app/sagas/auth';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from 'types';

import { Login } from './Login';
import { _DispatchProps, _StateProps, _Styles } from './types';

const styles: _Styles = (theme) => ({
  about: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    textAlign: 'center'
  },
  content_wrapper: {
    marginTop: theme.spacing.unit,
  },
  content: {
    padding: '24px',
    [theme.breakpoints.only('xs')]: {
      borderRadius: 0,
      boxShadow: 'none',
      height: '100%',
      overflowY: 'scroll',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '432px',
    },
  },
  h1: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    lineHeight: '1.6'
  },
  header: {
    display: 'flex',
    justifyContent: 'left'
  },
  button_wrapper: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    justifyContent: 'left',
  },
  logo: {
    height: '32px',
    width: 'auto',
  },
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    width: '100vw',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  submit: {
    boxShadow: 'none',
    backgroundColor: '#005AA3',
    "&:hover": {
      backgroundColor: '#003561',
    }
  },
  text_field: {
    display: 'block',
    marginTop: theme.spacing.unit,
  },
});

const stateToProps = (state: StoreState): _StateProps => ({
  response: state.auth.loginResponse,
});

const dispatchToProps = (dispatch: Dispatch): _DispatchProps => ({
  onLoad: () => {
    const action = setState({ isLoading: false });
    dispatch(action);
  },
  onLogin: (username, password, remember) => {
    const action = login(username, password, remember);
    dispatch(action);
  },
});

const Analytics = analytics(Login);
const Styled = withStyles(styles)(Analytics);
const ConnectedLogin = connect(stateToProps, dispatchToProps)(Styled);
export default ConnectedLogin;
