import { setKortforsyningenToken } from 'app/reducers/auth';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

function makeRequest() {
  const { password, username } = FEATURES.kortforsyningen.auth;
  const url = `https://services.kortforsyningen.dk/service?request=gettoken&login=${username}&password=${password}`;
  return axios.get<string>(url, {
    responseType: 'text',
  });
}

const GET_TOKEN = 'sagas/kortforsyningen/get-token';

export function getToken() {
  return { type: GET_TOKEN as typeof GET_TOKEN };
}

export type GetTokenAction = ReturnType<typeof getToken>;

export type OrtofotoAction =
  | GetTokenAction;

function* getTokenSaga(action: OrtofotoAction) {
  try {
    const response = yield call(makeRequest);
    const token = response.data;
    yield put(setKortforsyningenToken(token));
  } catch {
    // do nothing
  }
}

export function* kortforsyningenSaga() {
  yield takeLatest(GET_TOKEN, getTokenSaga);
}
