import * as React from 'react';
import { utils } from 'gridtools/types';
import { Report, ReportLoaderBase, ReportUrlParams, formatNumber, withReportParams } from '../CablePull';
import { loadData } from './load';
import { prepareReportData } from './utils';

const KREDSLOB = 'Kredsløb';

type ReportData = utils.UnwrapPromise<ReturnType<typeof loadData>>;

export const createPdfReport = (params: ReportUrlParams) => {
  return new KredslobReport(params).createPdf();
};

class KredslobReport extends ReportLoaderBase<ReportData> {
  constructor(props: ReportUrlParams) {
    super(props, 'Kredsløbsdiagram');
  }

  protected loadData() {
    return loadData(this.options.id, this.options.token);
  }

  protected renderReport({ details, items, length }: ReportData) {
    const footerProps = {
      ...this.options,
      name: details.identification,
      objectTypeName: KREDSLOB,
      notes: details.comment
    };
    const reportItems = prepareReportData(items);
    const headers = [
      `${KREDSLOB}: ${details.identification}`,
      `Bølgelængde: ${formatNumber(details.wave_length_nm || 0)} nm`,
      `Totallængde: ${formatNumber(length)} m`
    ];
    return <div>
      {this.renderPdfButton()}
      <br />

      <Report headers={headers}

              items={reportItems}
              labelFontSize='small'

              footer={footerProps}
              {...this.pdf.reportProps}
              />
    </div>;
  }
}

export default withReportParams()(KredslobReport);
