import * as api from 'lib/go/utils';
import { route } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'routes';

type IDOnly = route.RouteIDOnly;
type Full = route.Route;
type Debug = route.RouteDebug;

type CSIDOnly = route.ChildStructureIDOnly;
type CSFull = route.ChildStructure;
type CSDebug = route.ChildStructureDebug;

type PSIDOnly = route.ParentStructureIDOnly;
type PSFull = route.ParentStructure;
type PSDebug = route.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<route.Stats>(namespace, resource);
