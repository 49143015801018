import { stringComparer } from 'gridtools/utils/tests';

export function flatten<T>(xs: T | T[]): T[] {
  if (Array.isArray(xs)) {
    const ys: T[] = [];
    const n = xs.length;
    for (let i = 0; i < n; i++) {
      const x = flatten(xs[i]);
      ys.push(...x);
    }
    return ys;
  }
  return [xs];
}

export function flatMap<T1, T2>(input: T1[], func: (t: T1) => T2[]) {
  const result: T2[] = [];
  input.forEach(t => result.push(...func(t)));
  return result;
}

export function mapSort<Item>(items: Item[], transform: (item: Item) => number | string): Item[] {
  function isNumber(value: string | number): value is number {
    return typeof value === 'number' && !isNaN(value);
  }

  function sort(i1: Item, i2: Item) {
    const value1 = transform(i1);
    const value2 = transform(i2);
    if (isNumber(value1) && isNumber(value2)) {
      return value1 - value2;
    }
    if (isNumber(value1) && !isNumber(value2)) {
      return -1;
    }
    if (!isNumber(value1) && isNumber(value2)) {
      return 1;
    }
    if (!isNumber(value1) && !isNumber(value2)) {
      return stringComparer(value1, value2);
    }
    return 0;
  }

  return items.sort(sort);
}

export function unique<Item extends { id_source: string; }>(items: Item[]): Item[] {
  const entries = items.map((item) => [item.id_source, item] as [string, Item]);
  const map = new Map<string, Item>(entries);
  return Array.from(map.values());
}
