import * as React from 'react';
import { RectCoords, withReportSize, textContentRender, imageContentRender } from './FooterCell';
import { buildPageNumber, formatDate, wrapText } from './utils';
import { PAGE_NUMBER_ATTR, STYLES } from './constants';
import { _AddressBlock, _Dimensions, _FooterOptions } from './types';
import { ExcludeKeys } from 'gridtools/types/utils';

type _FooterProps = ExcludeKeys<_FooterOptions, 'customer'>;
type _FooterCustomerProps = {
  customer: string;
  address: _AddressBlock[];
  logo?: string;
};
type ReportFooterProps =
  & { dimensions: _Dimensions }
  & _FooterProps
  & _FooterCustomerProps;

const goLogoImage = require('assets/go-logo.png');

const customerContentRender = (customer: string, address: _AddressBlock[]) =>
  ({ x1, y1, x2, y2 }: RectCoords) => {
    const { normal, small } = STYLES.text;
    const items = [
      <text key='custName'
            x={x1 + 20}
            y={(y1 + y2 + normal.fontSize) / 2}
            textAnchor='left'
            style={normal}>
        {customer}
      </text>
    ];

    const margin = 5;
    let right = x2 - margin;
    for (let i = address.length - 1; i >= 0; i--) {
      const block = address[i];
      if (!block.line1 && !block.line2) continue;

      const left = right - block.width - 2 * margin;
      const x = (left + right) / 2;

      let y = y2 - small.fontSize;
      for (const text of [block.line2, block.line1]) {
        if (!text) continue;

        items.push(
          <text key={`cust${i}-${y}`}
                x={x} y={y}
                textAnchor='middle'
                style={small}>
            {text}
          </text>
        );
        y -= small.fontSize * 1.7;
      }

      right = left;
    }

    return <>{items}</>;
  };

const getNotesTextArea = ({ x1, y1, x2, y2 }: RectCoords) => {
  const top = y1 + STYLES.text.small.fontSize * 1.4,
    margin = 5,
    bottom = y2 - margin;
  return {
    width: x2 - x1 - 2 * margin,
    height: bottom - top,

    top,
    bottom
  };
};

const notesContentRender = (notes: string) =>
  (rect: RectCoords) => {
    const { large } = STYLES.text;
    const { height, width, top, bottom } = getNotesTextArea(rect);

    const style = large,
      fontSize = style.fontSize,
      lineHeight = fontSize * 1.4;

    const lines = wrapText(notes, fontSize, width);
    const blockHeight = Math.min(height, lines.length * lineHeight);

    const props = {
      x: (rect.x1 + rect.x2) / 2,
      y: (top + bottom - blockHeight) / 2,
      style,
      textAnchor: 'middle'
    };

    return <>
      {
        textContentRender('Notes')(rect)
      }
      {lines.map((l, i) => {
        props.y += lineHeight;
        return props.y > bottom ? null // clip text if too high
          : <text {...props} key={i}>{l}</text>;
      })}
    </>;
  };

const ReportFooter: React.FC<ReportFooterProps> = props => {
  const text = textContentRender;

  const customerInfo = customerContentRender(props.customer, props.address);
  const customerLogo = props.logo ? imageContentRender(props.logo) : undefined;
  const goLogo = imageContentRender(goLogoImage, 'right');

  const pageNumber = text('Page', buildPageNumber(1, 1), {
    ['data-' + PAGE_NUMBER_ATTR]: buildPageNumber(1, 1)
  });

  const notes = props.notes ? notesContentRender(props.notes) : text('Notes');

  const FooterCell = withReportSize(props.dimensions);
  return <>
    <FooterCell r={0} c={0} rowspan={4} contentRender={customerLogo}/>

    <FooterCell r={0} c={1} colspan={4}
                contentRender={text(props.objectTypeName, props.name)} />
    <FooterCell r={0} c={5}
                contentRender={text('SW-ID', props.id)} />

    <FooterCell r={1} c={1} colspan={5} contentRender={text('filename')} />

    <FooterCell r={2} c={2} contentRender={pageNumber} />
    <FooterCell r={2} c={3} contentRender={text('Scale', '1:1')} />
    <FooterCell r={2} c={4} contentRender={text('Date', formatDate(new Date()))} />
    <FooterCell r={2} c={5} contentRender={text('Drawn by', props.user)} />

    <FooterCell r={3} c={1} colspan={5} contentRender={customerInfo} />

    <FooterCell r={0} c={6} rowspan={4} contentRender={notes} />

    <FooterCell r={0} c={7} rowspan={4} contentRender={goLogo} />
  </>;
};
export default ReportFooter;
