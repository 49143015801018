import { orderedParentStructure, parentStructure } from 'gridtools/go/telco/fiber_cable';
import { parentStructure as cableParentStructure } from 'gridtools/go/telco/fiber_cable_node';
import { childStructure } from 'gridtools/go/telco/route_node';
import { go } from 'gridtools/types';
import { FiberCableObject } from './types';

const fetchResult = <T>(req: go.request.Request<T, any>) => {
  return req.request.then(d => d.type === 'success'
    ? Promise.resolve(d.result)
    : Promise.reject(d));
};

export class ApiHelper {
  constructor(token: string, url = API_URLS.gridoptimizer,
              private readonly childStruct = childStructure(url, token),
              private readonly parentStruct = parentStructure(url, token),
              private readonly cableParentStruct = cableParentStructure(url, token),
              private readonly ordered = orderedParentStructure(url, token)) {
  }

  fetchHeader(id: string) {
    return fetchResult(this.parentStruct({ id, details: 'full' }))
      .then(r => r.root.details);
  }

  // get end info
  fetchStructure(id: string, outputGeoms = false) {
    return fetchResult(this.cableParentStruct({ id: id + '_end', details: 'full' }))
      .then(r => {
        const root = r.root;
        let endFiberNodeId = '';
        const { located_in = [] } = root;
        const routeNode = located_in.find(t => t.type === 'telco_route_node');
       
        if (routeNode) {
          endFiberNodeId = routeNode.id_source;
          // fetch child structure for the fiber end node to find the customer info
          return fetchResult(this.childStruct({ id: endFiberNodeId, details: 'full', output_geoms: outputGeoms})).then(r => {
            const { contains = [] } = r.root;
            const customerEnd:any = contains.find(t => t.type === 'telco_customer');

            return fetchResult(this.ordered(({ id, details: 'full', output_geoms: outputGeoms })))
              .then(({ lines, objects }) => {
                objects.forEach(obj => {
                  if (obj.type === 'telco_fiber_cable_node' && obj.id_source.includes('_end')) {
                    const fiberCableNode: any = obj;
                    if (customerEnd) {
                      fiberCableNode.located_in.push(customerEnd);
                    }
                  }
                });
                return {
                  lines,
                  objects: objects as any as FiberCableObject[]
                }
              });
          });
        }

        return fetchResult(this.ordered(({ id, details: 'full', output_geoms: outputGeoms })))
          .then(({ lines, objects }) => ({
            lines,
            objects: objects as any as FiberCableObject[]
          }));
      });
  }
}
