import { Login as LoginWrapper, LoginChangedFields } from 'gridtools/ui';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import * as intl from 'react-intl-universal';
import { Typography, Paper, Button } from '@material-ui/core/';

export interface SAMLResponseError {
  message: string;
  name: string;
  status: number;
}

import { _Props, _State } from './types';

export class Login extends React.Component<_Props, _State> {
  static getDerivedStateFromProps(nextProps: _Props, prevState: _State): null | Partial<_State> {
    if (nextProps.response !== prevState.prevResponse) {
      const { response } = nextProps;
      const nextState: Partial<_State> = { prevResponse: response };
      nextState.form = response === null || response.type === 'cancelled' || response.type === 'success'
        ? { ...prevState.form, error: undefined }
        : { ...prevState.form, error: response.error.message };
      return nextState;
    }
    return null;
  }

  private _loadingTimeout: any;

  constructor(props: _Props) {
    super(props);
    this.state = {
      form: {
        fields: {
          password: {
            label: intl.get('login.password'),
            value: '',
          },
          remember: {
            label: intl.get('login.remember'),
            value: false,
          },
          username: {
            label: intl.get('login.username'),
            value: '',
          },
        },
        onChange: this.onChange,
        onSubmit: this.onSubmit,
      },
      loading: false,
      logo: require('assets/go-logo.png'),
      prevResponse: props.response,
      text: {
        submit: intl.get('login.submit'),
        title: intl.get('login.title'),
      },
      version: intl.get('appBar.about.version', { version: VERSION }),
    };
  }

  componentDidMount() {
    this.props.onLoad();
    this.props.analytics.pageView(() => {
      const page = '/login';
      const title = 'Login';
      return { page, title };
    });
  }

  componentDidUpdate(prevProps: _Props) {
    if (this.props.response !== prevProps.response && this.props.response !== null) {
      this.clearLoadingTimeout();
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.clearLoadingTimeout();
  }

  clearLoadingTimeout() {
    if (this._loadingTimeout !== undefined) {
      clearTimeout(this._loadingTimeout);
    }
  }

  onChange = (fields: LoginChangedFields) => {
    const keys = Object.keys(fields) as Array<keyof LoginChangedFields>;
    keys.forEach((key) => {
      const field = fields[key];
      if (field !== undefined) {
        this.setState((prevState) => ({
          form: {
            ...prevState.form,
            error: undefined,
            fields: {
              ...prevState.form.fields,
              [key]: {
                ...prevState.form.fields[key],
                value: field,
              },
            },
          },
        }));
      }
    });
  };

  onSubmit = () => {
    const fields = this.state.form.fields;
    const password = fields.password.value;
    const remember = fields.remember.value;
    const username = fields.username.value;
    this.props.onLogin(username, password, remember);
    this.clearLoadingTimeout();
    this._loadingTimeout = setTimeout(() => this.setState({ loading: true }), 250);
    this.renderSamlErrorWrapper = this.renderSamlErrorWrapper.bind(this);
  };

  refreshPage = () => {
    window.location.reload();
  }

  renderSamlErrorWrapper() {
    const { classes } = this.props;
    const title = intl.get('saml.access_denied_title');
    const message = intl.get('saml.access_denied_content');
    const goBackText = intl.get('saml.access_denied_btn_text');
    return (title === undefined || title === null) ? null : (
      <Paper className={classes.content}>
        <>
          <div className={classes.header}>
            {<Typography className={classes.h1} variant='h1'>{title}</Typography>}
          </div>
        </>
        <div className={classes.content_wrapper}>
          <div>
            {<Typography variant='body1'>{message}</Typography>}
          </div>
          <div className={classes.button_wrapper}>
            <Button className={classes.submit} color='primary' variant='contained' type='submit' onClick={this.refreshPage}>
              {goBackText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  render() {
    const samlLogin = FEATURES.saml;
    const { form, loading, logo, text, version, prevResponse } = this.state;
    const { classes } = this.props;
    return (
      <DocumentTitle title='Grid Optimizer'>
        <>
          { samlLogin && prevResponse?.type !== 'success' ? (
            <div className={classes.root}>
              {this.renderSamlErrorWrapper()}
            </div>
          ) : (
            <LoginWrapper form={form} loading={loading} logo={logo} text={text} />
          )
          }
          
          <footer className={classes.about}>
            <Typography variant='subtitle1'>{version}</Typography>
          </footer>
        </>
      </DocumentTitle>
    );
  }
}
