import { Specification } from 'gridtools/types/go/telco/output';
import { formatNumber } from '../CablePull';
import { ApiHelper, IdentificationBuilder } from './utils';
import { _Item, _LocatedIn, _Node, _Parents, NodeDetails, NodeLineDetails } from './types';

export const getApiHelper = (token: string, outputGeoms = false) =>
  new ApiHelper(token, API_URLS.gridoptimizer, outputGeoms);

export const loadData = async (id: string, token: string, outputGeoms = false) => {
  const api = getApiHelper(token, outputGeoms);

  const { root: { located_in = [], details } } = await api.fetchParentStructure(id);

  const lengths: number[] = [];
  const items = processPathParents(located_in, lengths, api);

  await api.getPendingRequests();

  items.sort((n1, n2) => n1.sequence - n2.sequence);

  return {
    details,
    length: lengths.reduce((p, x) => p + x, 0),
    items
  };
};

export const processPathParents = (parents: _Parents, lengths: number[], api: ApiHelper): _Node[] => {
  const portBuilder = new IdentificationBuilder();
  const fiberBuilder = new IdentificationBuilder();

  const items: _Node[] = [];
  parents.forEach(parent => {
    const { type, located_in = [], sequence } = parent;
    const list: _LocatedIn = [parent];

    switch (type) {
      case 'telco_fiber_port': {
        portBuilder.append(sequence, parent as any);

        const details = located_in ? getFiberPortDetails(located_in, sequence, portBuilder, list) : null;
        const objects = [
          ...(details && details.ports) || [parent],
          ...list.filter(t => t.type !== 'telco_fiber_port')
        ];
        api.startFetchingAddress(details);
        items[sequence] = { type, sequence, details, objects };
        break;
      }

      case 'telco_fiber_node': {
        const details = located_in ? getFiberNodeDetails(located_in, list, { location: undefined }) : null;
        api.startFetchingAddress(details);
        items[sequence] = { type, sequence, details, objects: list };
        break;
      }

      case 'telco_fiber': {
        fiberBuilder.append(sequence, parent as any);
        lengths[sequence] = 0;

        const details = getFiberDetails(located_in, sequence, fiberBuilder, lengths, list);
        const objects = [
          ...details.fibers,
          ...list.filter(t => t.type !== 'telco_fiber')
        ];
        items[sequence] = { type, sequence, details, objects };
        break;
      }
    }
  });
  return items;
};

const getFiberPortDetails = (located_in: _LocatedIn = [],
                             sequence: number, portIds: IdentificationBuilder,
                             objects: _LocatedIn,
                             rack = '', card = ''): NodeDetails | null => {
  for (const p of located_in) {
    const port = portIds.build(sequence);
    const ports = portIds.getObjects(sequence);
    objects.push(p);
    
    switch (p.type) {
      case 'telco_customer': {
        let installationNumber: any = '';
        let installationStatus: any = '';
        var rackEquipment = objects.filter(obj => {
          return obj.type === 'telco_rack_equipment' && ( obj.details.custom_data && obj.details.type === 'Rack')
        });

        objects.forEach((obj) => {
          if (obj.type === 'telco_rack_equipment' && obj.details && obj.details.type === 'Rack') {
            const { details: {custom_data} } = obj;
            if (custom_data) {
              if (custom_data.Installationsnummer) {
                installationNumber = custom_data.Installationsnummer;
              }
              if (custom_data.Installationsstatus) {
                installationStatus = custom_data.Installationsstatus;
              }
            }
            
          }
        });

        const customer = p.details;
        return {
          name: customer.identification,
          subName: 'Kunde',
          description: customer.address_description,
          location: p.details.location,
          installationNumber: installationNumber,
          installationStatus: installationStatus,
        };
      }

      case 'telco_container':
        return {
          name: `${p.details.type_source}: ${p.details.identification}`,
          subName: p.details.specification || '',
          description: p.details.type === 'HUB' ? `${rack} / ${card}` : '',
          port,
          ports,
          location: p.details.location,

          type: p.type,
          id_source: p.id_source
        };

      case 'telco_route_node':
        return {
          port,
          ports,
          location: p.details.location,
          type: p.type,
          id_source: p.id_source
        };

      case 'telco_rack_equipment': {
        if (p.details.type === 'Card') {
          card = p.details.identification || '';
        } else if (p.details.type === 'Rack') {
          rack = p.details.identification || '';
        }
        break;
      }
    }

    if ('located_in' in p) {
      return getFiberPortDetails(p.located_in, sequence, portIds, objects, rack, card);
    }
  }

  return null;
};

const getFiberNodeDetails = (located_in: _LocatedIn = [],
                             objects: _LocatedIn,
                             result: NodeDetails): NodeDetails => {
  const getText = (spec: Specification) => spec.specification
    ? `${spec.identification || ''} (${spec.specification})`
    : `${spec.identification || ''}`;

  for (const p of located_in) {
    objects.push(p);
    switch (p.type) {
      case 'telco_optical_splice':
        result.subName = getText(p.details);
        result.location = p.details.location;

        if (p.located_in) {
          result = getFiberNodeDetails(p.located_in, objects, result);
        }

        break;

      case 'telco_container':
        result = {
          ...result,
          name: `${p.details.type_source}: ${getText(p.details)}`,
          id_source: p.id_source,
          type: p.type
        };
        if (p.details.location && !result.location) {
          result.location = p.details.location;
        }
        break;

      default:
        if ('located_in' in p) {
          return getFiberNodeDetails(p.located_in, objects, result);
        }
    }
  }

  return result;
};

const getFiberDetails = (located_in: _LocatedIn = [],
                         sequence: number,
                         fiberIds: IdentificationBuilder,
                         lengths: number[],
                         objects: _LocatedIn): NodeLineDetails => {
  const result: NodeLineDetails = {
    above: '',
    below: '',
    fibers: [],
    centerline: [],
  };

  for (const p of located_in) {
    objects.push(p);
    if (p.type === 'telco_fiber_cable') {
      const { geographical_length_m: len, identification, specification, centerline } = p.details;
      result.below = [ identification, specification, len && `(${formatNumber(len)} m)` ]
        .filter(Boolean).join(' ');
      if (centerline) {
        result.centerline.push(centerline);
      }
      lengths[sequence] = (lengths[sequence] || 0) + len;
    } else if ('located_in' in p) {
      return getFiberDetails(p.located_in, sequence, fiberIds, lengths, objects);
    }
  }

  result.fibers = fiberIds.getObjects(sequence);
  result.above = result.fibers.map(t => {
    if (t.type !== 'telco_fiber') return null;
    const { identification, color_source, color } = t.details;
    return [identification, color_source || color].filter(Boolean).join(' ');
  }).filter(Boolean).join(', ');
  return result;
};

export const toReportItem = (node: _Node, i: number, list: _Node[]): _Item | null => {
  if (node.type === 'telco_fiber') {
    return null;
  }

  const { details, type } = node;

  const label = details ? [
    details.name,
    details.subName,
    details.description,
    details.port,
    details.address && details.address.address,
    details.installationNumber,
    details.installationStatus,
  ].filter(s => s) : [];
  const objItem = {
    label,
    location: (details && details.location) || undefined
  };
  const noLineNode: _Item = { ...objItem, centerline: [] };

  if (i === list.length - 1) {
    return type === 'telco_fiber_port' ? noLineNode : null;
  }

  const next = list[i + 1];

  if (type === 'telco_fiber_port') {
    const next2 = list[i + 2];
    if (next && next2) {
      if (next.type === 'telco_fiber_node' && next2.type === 'telco_fiber') {
        return {
          ...next2.details,
          fiber: next2.objects,
          ...objItem
        };
      }
      if (next.type === 'telco_fiber_port') {
        return {
          ...noLineNode,
          above: 'PATCH',
          style: 'dashed'
        };
      }
    }
    return noLineNode;
  }

  if (type === 'telco_fiber_node') {
    const prev = list[i - 1];
    if (prev && prev.type === 'telco_fiber_node'
      && next && next.type === 'telco_fiber') {
      return {
        ...next.details,
        fiber: next.objects,
        ...objItem
      };
    }
  }

  return null;
};
