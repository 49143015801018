import { LoadState } from 'app/components/LoadState';
import * as Loadable from 'react-loadable';

const LoadableMap = Loadable({
  delay: 0,
  loader: () => import('./hoc'),
  loading: LoadState,
});

export default LoadableMap;
