import { getJson, removeItem, setJson } from 'app/utils/storage';
import { go } from 'gridtools/types';

const USER_KEY = 'user';

export function loadUser() {
  return getJson<go.users.UsersLoginResult>(USER_KEY);
}

export function removeUser() {
  removeItem(USER_KEY);
}

export function saveUser(user: go.users.UsersLoginResult) {
  setJson(USER_KEY, user);
}
