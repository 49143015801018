import { geojson } from 'gridtools/types';
import { geojsonEquals } from 'gridtools/utils/equals';
import { DroneSelectionState } from 'types';

const ADD = 'store/drone/selection/add';
const REMOVE = 'store/drone/selection/remove';

type AddAction = ReturnType<typeof add>;
type RemoveAction = ReturnType<typeof remove>;

export type DroneSelectionAction =
  | AddAction
  | RemoveAction;

export function add(selection: geojson.Polygon) {
  return { selection, type: ADD as typeof ADD };
}

export function remove(selection: geojson.Polygon) {
  return { selection, type: REMOVE as typeof REMOVE };
}

function _set(state: DroneSelectionState, action: AddAction): DroneSelectionState {
  return state === null ? [action.selection] : [...state, action.selection];
}

function _unset(state: DroneSelectionState, action: RemoveAction): DroneSelectionState {
  if (state === null) {
    return null;
  }
  const selections = state.filter((selection) => !geojsonEquals(selection, action.selection));
  return selections.length === 0 ? null : selections;
}

export function droneSelectionReducer(state: DroneSelectionState, action: DroneSelectionAction): DroneSelectionState {
  switch (action.type) {
    case ADD: return _set(state, action);
    case REMOVE: return _unset(state, action);
    default: return state;
  }
}
