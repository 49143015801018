import * as api from 'lib/go/utils';
import { customer } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'customers';

type IDOnly = customer.CustomerIDOnly;
type Full = customer.Customer;
type Debug = customer.CustomerDebug;

type ConnectionIDOnly = customer.ConnectionIDOnly;
type ConnectionFull = customer.Connection;
type ConnectionDebug = customer.Connection;

type CSIDOnly = customer.ChildStructureIDOnly;
type CSFull = customer.ChildStructure;
type CSDebug = customer.ChildStructureDebug;

type PSIDOnly = customer.ParentStructureIDOnly;
type PSFull = customer.ParentStructure;
type PSDebug = customer.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<customer.Stats>(namespace, resource);
