// tslint:disable-next-line
import { StyledComponentProps } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
// tslint:disable-next-line
import * as React from 'react';

import { _Styles } from './interfaces';
import { Login } from './Login';

const styles: _Styles = (theme) => ({
  bottom: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 3 * theme.spacing.unit,
  },
  checkbox: {
    marginTop: theme.spacing.unit,
  },
  content: {
    padding: '24px',
    [theme.breakpoints.only('xs')]: {
      borderRadius: 0,
      boxShadow: 'none',
      height: '100%',
      overflowY: 'scroll',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '432px',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  header_left: {
    flex: 1,
  },
  header_right: {},
  loading: {
    color: theme.palette.primary.main,
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },
  loading_wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  logo: {
    height: '32px',
    width: 'auto',
  },
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  submit: {
    float: 'right',
  },
  text_field: {
    display: 'block',
    marginTop: theme.spacing.unit,
  },
});

const StyledLogin = withStyles(styles)(Login);
export default StyledLogin;
