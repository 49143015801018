import * as React from 'react';
import { calcReportDimensions, measureText } from './utils';
import Header from './Header';
import ReportData from './ReportData';
import Footer from './Footer';
import { STYLES } from './constants';
import { _AddressBlock, _ReportCustomerSettings, ReportProps } from './types';

const getAddressBlocks = (customer: _ReportCustomerSettings) => {
  const getBlock = (line1: string | undefined,
                    line2: string | undefined): _AddressBlock => {
    const font = STYLES.text.small.fontSize;
    const width = Math.max(
      line1 ? measureText(line1, font) : 0,
      line2 ? measureText(line2, font) : 0
    );
    return { line1, line2, width };
  };

  const addressBlock = Array.isArray(customer.address)
    ? customer.address
    : customer.address.split(', ');
  const addressMiddle = addressBlock.length / 2;

  return [
    getBlock(
      addressBlock.slice(0, addressMiddle).join(', '),
      addressBlock.slice(addressMiddle).join(', ')
    ),
    getBlock(
      customer.phone && `Tlf. ${customer.phone}`,
      customer.fax && `Fax ${customer.fax}`
    ),
    getBlock(
      customer.web,
      customer.email && `E-mail ${customer.email}`
    )
  ];
};

const Report: React.FC<ReportProps> = (props) => {
  const { headers, items, footer, onMeasured, svgRef, labelFontSize } = props;

  const address = getAddressBlocks(footer.customer);
  const { px: dimensions, mm: svgSize, viewBox }
    = calcReportDimensions(items.length, address);

  if (onMeasured) {
    onMeasured(dimensions);
  }

  return (
    <svg {...svgSize}
         viewBox={viewBox}
         ref={svgRef}
         style={STYLES.svg}>

      <Header headers={headers} />

      <ReportData items={items}
                  reportWidth={dimensions.width}
                  labelFontSize={labelFontSize}/>

      <Footer {...footer}
              customer={footer.customer.name}
              address={address}
              logo={footer.customer.logo}
              dimensions={dimensions}/>
    </svg>
  );
};

export default Report;
// export default ReportLoader;
