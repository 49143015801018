import { go } from 'gridtools/types/index';
import { stringComparer } from 'gridtools/utils/tests/comparers';
import { GOApiPaths, GOObjects, HistoryData, HistoryItem, HistoryResponseData } from 'types';
import { ApiHelper } from './helper';
import { wrapRequest } from './utils';

type _Key = keyof GOApiPaths & keyof GOObjects;

const readHistory = (items: HistoryItem[]): HistoryData | null => {
  const sorted = items.sort((a, b) => stringComparer(a.metadata.from, b.metadata.from));
  if (sorted.length === 0) return null;

  const first = sorted[0].metadata;
  const last = sorted[sorted.length - 1].metadata;
  return {
    created: new Date(first.from),
    createdBy: first.user,
    changed: new Date(last.from),
    changedBy: last.user
  };
};

export function history<K extends _Key>(type: K, path: GOApiPaths[K]) {
  return function(base: string, token: string) {
    const api = new ApiHelper(base, token, token);

    return function(options: { id: string }) {
      type Result = HistoryData | null;
      type Error = go.error.ObjectError;

      const url = `/${path}/${options.id}/history`;
      const request = wrapRequest<Result, Error>(async () => api
        .invoke<HistoryResponseData<K>>(url, undefined, 'get')
        .then(r => r.transactions[0] ? r.transactions[0][type] : [])
        .catch(() => [])
        .then(readHistory));

      return api.goRequest(request);
    };
  };
}
