import * as api from 'lib/go/utils';
import { logical_location } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'logical_locations';

type IDOnly = logical_location.LogicalLocationIDOnly;
type Full = logical_location.LogicalLocation;
type Debug = logical_location.LogicalLocationDebug;

type CSIDOnly = logical_location.ChildStructureIDOnly;
type CSFull = logical_location.ChildStructure;
type CSDebug = logical_location.ChildStructureDebug;

type PSIDOnly = logical_location.ParentStructureIDOnly;
type PSFull = logical_location.ParentStructure;
type PSDebug = logical_location.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<logical_location.Stats>(namespace, resource);
