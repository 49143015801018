import { ExcludeKeys } from 'gridtools/types/utils';
import { GOChildren, GOParents } from 'types/go';

type FindOptions<T> = {
  depth?: number;
  findFirst?: boolean;
  check?: (obj: T) => boolean;
};
type FindFirstOptions<T> = ExcludeKeys<FindOptions<T>, 'findFirst'>;

const findImpl = (rel: 'contains' | 'located_in', c: { type: string }, t: string,
                  options: FindOptions<any> = {}) => {
  const done = () => options.findFirst && result.length > 0;

  const result: any[] = [];
  if (c.type === t || `${c.type}_vertex` === t) {
    const checked = options.check
      ? options.check(c)
      : true;
    if (checked) {
      result.push(c);
    }
  }

  if (done()) return result;

  const arr = (c as any)[rel];
  const depth = typeof options.depth === 'number' ? options.depth : 9999;
  if (arr && Array.isArray(arr) && depth > 0) {
    for (const a of arr) {
      const found = findImpl(rel, a, t, { ...options, depth: depth - 1 });
      result.push(...found);

      if (done()) {
        return result;
      }
    }
  }

  return result;
};

type _CType = keyof GOChildren;
type _CRoot<T extends _CType = _CType> = GOChildren[T]['root'];

type _PType = keyof GOParents;
type _PRoot<T extends _PType = _PType> = GOParents[T]['root'];

export function findHierarchy<T extends _CType>(rel: 'contains', c: _CRoot, t: T,
  options?: FindOptions<_CRoot<T>>): _CRoot<T>[];
export function findHierarchy<T extends _PType>(rel: 'located_in', c: _PRoot, t: T,
  options?: FindOptions<_PRoot<T>>): _PRoot<T>[];
export function findHierarchy(relation: 'contains' | 'located_in', c: { type: string }, t: string,
                              options?: FindOptions<any>) {
  return findImpl(relation, c, t, options);
}

export function findHierarchyFirst<T extends _CType>(rel: 'contains', c: _CRoot, t: T,
  options?: FindFirstOptions<_CRoot<T>>): _CRoot<T> | undefined;
export function findHierarchyFirst<T extends _PType>(rel: 'located_in', c: _PRoot, t: T,
  options?: FindFirstOptions<_PRoot<T>>): _PRoot<T> | undefined;
export function findHierarchyFirst(relation: 'contains' | 'located_in', c: { type: string }, t: string,
                                   options?: FindFirstOptions<any>) {
  const result = findImpl(relation, c, t, {
    ...options,
    findFirst: true
  });
  return result.length > 0 ? result[0] : undefined;
}

export function findChildren<T extends _CType>(type: T, root: _CRoot, options?: FindOptions<_CRoot<T>>) {
  return findHierarchy<T>('contains', root, type, options);
}
export function findParents<T extends _PType>(type: T, root: _PRoot, options?: FindOptions<_PRoot<T>>) {
  return findHierarchy<T>('located_in', root, type, options);
}

export function findFirstChild<T extends _CType>(type: T, root: _CRoot) {
  return findHierarchyFirst<T>('contains', root, type);
}
export function findFirstParent<T extends _PType>(type: T, root: _PRoot) {
  return findHierarchyFirst<T>('located_in', root, type);
}
