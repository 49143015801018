import * as authActions from 'app/reducers/auth';
import * as loadingActions from 'app/reducers/loading';
import { saveUser } from 'app/utils/users';
import * as api from 'gridtools/go/users';
import { utils } from 'gridtools/types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getSamlUrl, SAMLRequestResponse } from './saml';

const AUTO_LOGIN = 'sagas/auth/auto-login';
const LOGIN = 'sagas/auth/login';
const LOGOUT = 'sagas/auth/logout';
const SAML_LOGIN = 'sagas/auth/saml-login';

export type LoginResponse = utils.UnwrapPromise<ReturnType<ReturnType<typeof api.login>>['request']>;
export type LogoutResponse = utils.UnwrapPromise<ReturnType<ReturnType<typeof api.logout>>['request']>;
export type RetrieveResponse = utils.UnwrapPromise<ReturnType<ReturnType<typeof api.retrieve>>['request']>;
export type SAMLLoginResponse = utils.UnwrapPromise<ReturnType<ReturnType<typeof api.samlLogin>>['request']>;

export type LoginCallback = (response: LoginResponse) => void;
export type LogoutCallback = (response: LogoutResponse) => void;

export type AutoLoginAction = ReturnType<typeof autoLogin>;
export type LoginAction = ReturnType<typeof login>;
export type LogoutAction = ReturnType<typeof logout>;
export type SAMLLoginAction = ReturnType<typeof samlLogin>;

export function autoLogin(id: number, token: string) {
  return { id, token, type: AUTO_LOGIN as typeof AUTO_LOGIN };
}

export function login(username: string, password: string, remember: boolean, callback?: LoginCallback) {
  return { callback, password, remember, type: LOGIN as typeof LOGIN, username };
}

export function logout(token: string, callback?: LogoutCallback) {
  return { callback, token, type: LOGOUT as typeof LOGOUT };
}

export function samlLogin(samlResponse: string) {
  return { saml_response: samlResponse, type: SAML_LOGIN as typeof SAML_LOGIN };
}

function* autoLoginSaga(action: AutoLoginAction) {
  yield put(loadingActions.startLoggingIn());
  try {
    const retrieve = () => api.retrieve(API_URLS.gridoptimizer, action.token)(action.id.toString()).request;
    const response: RetrieveResponse = yield call(retrieve);
    if (response.type === 'success') {
      const username = response.result.username;
      yield put(authActions.login(action.id, action.token, username));
    }
    yield put(authActions.setLoginResponse(response));
  } catch {
    // do nothing
  }
  yield put(loadingActions.finishLoggingIn());
}

function* loginSaga(action: LoginAction) {
  try {
    const boundLogin = api.login(API_URLS.gridoptimizer);
    const getResponse = () => boundLogin({ username: action.username, password: action.password }).request;
    const response: LoginResponse = yield call(getResponse);
    yield getAndStoreUser(response, action.remember, action.callback);
  } catch {
    // do nothing
  }
}

async function getSamlLogoutUrl() {
  try {
    const response: SAMLRequestResponse = await getSamlUrl();
    const samlRequest = response.data.request_url;

    try {
      const url = document.createElement('a');
      url.href = samlRequest;
      url.search = '?wa=wsignout1.0';
      url.hash = '';

      return url.href;
    } catch (e) {
      console.error(e);
      return samlRequest;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}

function* logoutSaga(action: LogoutAction) {
  try {
    const boundLogout = api.logout(API_URLS.gridoptimizer, action.token);
    const getResponse = () => boundLogout().request;
    const response: LogoutResponse = yield call(getResponse);
    if (response.type === 'success') {
      const logoutUrl = FEATURES.saml
        ? yield call(getSamlLogoutUrl)
        : null;

      if (logoutUrl) {
        window.location.href = logoutUrl;
      } else {
        yield put(authActions.logout());
      }
    }
    if (action.callback !== undefined) {
      action.callback(response);
    }
  } catch {
    // do nothing
  }
}

function* samlLoginSaga(action: SAMLLoginAction) {
  yield put(loadingActions.startLoggingIn());
  try {
    const getResponse = () => api.samlLogin(API_URLS.gridoptimizer)({ saml_response: action.saml_response }).request;
    
    const response: SAMLLoginResponse = yield call(getResponse);
    yield getAndStoreUser(response, false);
  } catch {
    // do nothing
  }
  yield put(loadingActions.finishLoggingIn());
}

function* getAndStoreUser(
  response: LoginResponse | SAMLLoginResponse,
  remember: boolean,
  callback?: (response: LoginResponse | SAMLLoginResponse
  ) => void
) {
  if (response.type === 'success') {
    const token = response.result.id;
    const id = response.result.userId;
    const retrieve = () => api.retrieve(API_URLS.gridoptimizer, token)(id.toString()).request;
    const retrieveResponse: RetrieveResponse = yield call(retrieve);
    if (retrieveResponse.type === 'success') {
      const username = retrieveResponse.result.username;
      yield put(authActions.login(id, token, username));
      if (remember) {
        saveUser(response.result);
      }
      yield put(authActions.setLoginResponse(response));
    } else {
      yield put(authActions.setLoginResponse(retrieveResponse));
    }
  } else {
    yield put(authActions.setLoginResponse(response));
  }
  if (callback !== undefined) {
    callback(response);
  }
}

export default function* authSaga() {
  yield takeLatest(AUTO_LOGIN, autoLoginSaga);
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(SAML_LOGIN, samlLoginSaga);
}
