import * as React from 'react';
import { Route } from 'react-router';
import { BlaeseplanReport } from 'app/reports/CablePull';
import { KredslobReport } from 'app/reports/OpticalPath';

const reportRoutes = [
  <Route key={0} path='/report/blaeseplan/:id' component={BlaeseplanReport} />,
  <Route key={1} path='/report/kredslob/:id' component={KredslobReport} />
];
export default reportRoutes;
