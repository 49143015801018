import { catchError } from 'gridtools/go/utils/response';
import { Response } from 'gridtools/types/go/request';
import { BaseError } from 'gridtools/types/go/error';
import { CreateError } from 'types';

export const wrapRequest = async <T, E extends BaseError = CreateError>(request: () => Promise<T>): Promise<Response<T, E>> => {
  try {
    const result = await request();
    return { type: 'success' as 'success', result };
  } catch (e) {
    return await catchError<E>(e);
  }
};
