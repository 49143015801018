import * as api from 'lib/go/utils';
import { conduit_adapter, container, route_node } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'conduit_adapters';

type IDOnly = conduit_adapter.ConduitAdapterIDOnly;
type Full = conduit_adapter.ConduitAdapter;
type Debug = conduit_adapter.ConduitAdapterDebug;

type CSIDOnly = conduit_adapter.ChildStructureIDOnly;
type CSFull = conduit_adapter.ChildStructure;
type CSDebug = conduit_adapter.ChildStructureDebug;

type LocatedInIDOnly = container.ContainerIDOnly | route_node.RouteNodeIDOnly;
type LocatedInFull = container.Container | route_node.RouteNode;
type LocatedInDebug = container.ContainerDebug | route_node.RouteNodeDebug;

type PSIDOnly = conduit_adapter.ChildStructureIDOnly;
type PSFull = conduit_adapter.ChildStructure;
type PSDebug = conduit_adapter.ChildStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const locatedIn = api.locatedIn<LocatedInIDOnly, LocatedInFull, LocatedInDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<conduit_adapter.Stats>(namespace, resource);
