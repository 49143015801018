import { createElement, Fragment } from 'react';
import { timeFormat, formatLocale, sum } from 'd3';
import { getFooterHeight, getReportHeight, MIN_HEIGHT_MM, STYLES, WIDTH_MM, WIDTH_PX } from './constants';
import {
  _AddressBlock,
  DataItem,
  FiberCableLineReport,
  FiberCableObject,
  FiberCableStructure,
  ReportItem,
  Unit
} from './types';
import {
  _LineInfo,
  getNodeRender,
  findConduitContainer,
  getInfo,
  getLineInfo,
  getNodeContainer,
  collectLineIds
} from './telco';

export const formatDate = timeFormat('%Y-%m-%d %H:%M:%S');
const formatDateForFile = timeFormat('%Y-%m-%d %H%M%S');

const customLocale = formatLocale({
  thousands: '.',
  grouping: [3]
} as any);
export const formatNumber = customLocale.format(',d');

export const getTotalLength = (items: Array<{ length?: number }>) =>
  sum(items, i => i.length || 0);

export const guardSwitch = <T>(tested: never, message: string, fallbackValue: T) => {
  // console.error(message + ' ' + tested);
  return fallbackValue;
};

const coeffToMm = (unit: Unit) => {
  const inch = 25.4;
  switch (unit) {
    case 'mm': return 1;
    case 'px': return WIDTH_MM / WIDTH_PX;
    case 'pt': return inch / 72;
    case 'in': return inch;

    default: return guardSwitch(unit, 'unknown unit', 1);
  }
};
export const convertUnit = (value: number, unit: Unit, target: Unit) => {
  const coeff = unit === target ? 1
    : coeffToMm(unit) / coeffToMm(target);
  return coeff * value;
};

export const measureText = (text: string, font: string | number) => {
  if (typeof font === 'number') {
    font = `normal ${font}px ${STYLES.svg.fontFamily}`;
  }

  const mt = measureText as { canvas?: HTMLCanvasElement };
  const canvas = mt.canvas || (mt.canvas = document.createElement('canvas'));

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = font;

  const metrics = context.measureText(text);
  return metrics.width;
};

// simple word-wrap text algorithm
//  splits the text into words (by spaces),
//  then builds lines: adds words while the line fits to maxWidth
export const wrapText = (text: string, fontSize: number, maxWidth: number) => {
  const measure = (s: string) => measureText(s, fontSize);

  if (measure(text) <= maxWidth) {
    return [ text ];
  }

  const words = text.split(' ');
  const lines = [] as string[];
  let current = '';
  for (const w of words) {
    const withNextWord = `${current} ${w}`.trim(),
      width = measure(withNextWord);
    if (width > maxWidth) {
      lines.push(current);
      current = w;
    } else {
      current = withNextWord;
    }
  }

  return lines.concat(current).filter(s => s);
};

export const calcReportDimensions = (itemsCount: number,
                                     address: _AddressBlock[]) => {
  const footerHeight = getFooterHeight(
    address.some(addr => !!addr.line1 && !!addr.line2)
  );

  const rows = Math.ceil(itemsCount / 3);
  const height = Math.max(
    convertUnit(getReportHeight(rows, footerHeight), 'px', 'mm'),
    MIN_HEIGHT_MM
  );

  const pxSizes = {
    width: WIDTH_PX,
    height: convertUnit(height, 'mm', 'px'),

    footerHeight
  };

  return {
    mm: {
      width: WIDTH_MM + 'mm',
      height: height + 'mm'
    },

    px: pxSizes,

    viewBox: `0 0 ${pxSizes.width} ${pxSizes.height}`
  };
};

export const buildFileName = (type: string, version: string | null) => {
  type = type.replace(/æ/g, 'ae').replace(/ø/g, 'o');
  version = version || 'Engineering Design';
  const date = formatDateForFile(new Date());

  return `${type} (${version}) ${date}.pdf`;
};

export const buildPageNumber = (page: number, totalPages: number) =>
  `${page} OF ${totalPages}`;

export const prepareStruct = (cableId: string, struct: FiberCableStructure): ReportItem[] => {
  type Node = {
    item: ReturnType<typeof getInfo>,
    line?: _LineInfo,
    collapsed?: boolean,

    type?: DataItem['type'],

    object: FiberCableObject
  };
  const nodes: Node[] = [];
  struct.objects.forEach((object, i) => {
    const item = getInfo(object, struct.lines);

    const last = i === struct.objects.length - 1;
    const strLine = !last && struct.lines[i];
    const line = strLine ? getLineInfo(strLine) : undefined;

    const cont = strLine && findConduitContainer(strLine);

    const base = { item, object, line };

    if (cont) {
      nodes.push({ item, object });
      (struct.lines[i] as FiberCableLineReport).located_in.forEach((loc) => {
        if (loc.type === 'telco_container') {
          nodes.push({
            ...base,
            item: getInfo(loc as any, struct.lines),
            type: 'passthrough'
          });
        }
      });
    } else {
      nodes.push(base);
    }
  });

  const knownLineIds = collectLineIds(struct.lines, cableId);

  for (let i = nodes.length - 2; i >= 1; i--) {
    const n = nodes[i],
      prev = nodes[i - 1];

    if (n.line && n.line.isRoute && prev.line && prev.line.isRoute) {
      const container = getNodeContainer(n.object);
      const {
        type: renderType,
        // objects
      } = getNodeRender(n.object, container, prev.line, n.line, id => knownLineIds.has(id));

      if (renderType === 'passthrough') {
        n.item = container;
        n.type = 'rn_container';
      } else if (renderType === 'skip') {
        n.collapsed = true;

        // append data to prev line
        n.line.length += prev.line.length;
        n.line.centerline.push(...prev.line.centerline);
        n.line.objects.push(...prev.line.objects); // , ...objects as any[]);
        prev.line = n.line;
      }
    }
  }

  return nodes
    .filter(n => !n.collapsed)
    .map(({ line, item, type = 'item' }): ReportItem => ({
      ...line as _LineInfo,
      label: item ? [item.label, item.spec, item.address && item.address.address].filter(Boolean) : [],
      type,
      objectType: item ? item.mainObjectType : undefined,
      location: (item && item.location) || undefined,

      getLineText: length => [
        line && line.below,
        length === undefined || isNaN(length) ? '' : `(${formatNumber(length)} m)`
      ].filter(Boolean).join(' '),

      objects: {
        item: item ? item.objects : [],
        line: line ? line.objects : []
      },

      address: item ? item.address : undefined
    }));
};

export const prepareArrays = <K extends string>(...keys: K[]) => {
  const map: Record<K, JSX.Element[]> = {} as any;
  keys.forEach(k => {
    map[k] = [];
  });

  const wrapArray = (items: any[]) => items.map((t, key) => createElement(Fragment, { key }, t));
  const wrap = (order = keys) => order.map(key => {
    const array = map[key] || [];
    return array.length > 0
      ? createElement('g', { key }, ...wrapArray(array))
      : null;
  });

  return {
    push: (key: K, ...content: JSX.Element[]) => map[key].push(...content),
    wrap,
    all: (order = keys) => order.map(k => map[k]),
  };
};
