import { DroneState } from 'types';

import { DroneAnalysisAction, droneAnalysisReducer } from './analysis';
import { DroneSelectionAction, droneSelectionReducer } from './selection';

export type DroneAction =
  | DroneAnalysisAction
  | DroneSelectionAction;

export function droneReducer(state: DroneState, action: DroneAction): DroneState {
  return {
    analysis: droneAnalysisReducer(state.analysis, action as DroneAnalysisAction),
    selection: droneSelectionReducer(state.selection, action as DroneSelectionAction),
  };
}
