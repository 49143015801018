import { Reducer } from 'redux';
import { WorkorderState } from 'types/store/workorders';

const OPEN_LIST = 'store/ui/actions/open-workorders';
const CLOSE_LIST = 'store/ui/actions/close-workorders';

export const openWorkorders = () => ({
  type: OPEN_LIST
} as const);
export const closeWorkorders = () => ({
  type: CLOSE_LIST
} as const);

type OpenListAction = ReturnType<typeof openWorkorders>;
type CloseListAction = ReturnType<typeof closeWorkorders>;
export type WorkordersAction = OpenListAction | CloseListAction;

const reducer: Reducer<WorkorderState, WorkordersAction> = (state, action) => {
  switch (action.type) {
    case OPEN_LIST: return { ...state, listOpened: true };
    case CLOSE_LIST: return { ...state, listOpened: false };
    default: return state!;
  }
};
export default reducer;
