import * as React from 'react';

import { MapActionContext } from './interfaces';

const { Consumer, Provider } = React.createContext<MapActionContext>({
  store: null,
});

export const MapActionContextConsumer = Consumer;
export const MapActionContextProvider = Provider;
