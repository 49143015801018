import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils/response';
import { api, error as _error, request as _request, telco } from 'lib/types/go';
import { Params } from 'lib/utils/http';

import { NAMESPACE, RESOURCE } from './constants';

function getParams(accessToken: string, opts: api.FiberCableNodeFiberTraceOutOptions): string {
  const params = new Params();
  params.set('access_token', accessToken);
  if (opts.date !== undefined) {
    params.set('date', opts.date); 
  }
  if (opts.depth !== undefined) {
    params.set('depth', opts.depth.toString()); 
  }
  if (opts.direction !== undefined) {
    params.set('direction', opts.direction); 
  }
  if (opts.version !== undefined) {
    params.set('version', opts.version); 
  }
  return params.toString();
}

const error = (err: AxiosError) => catchError<_error.ObjectError>(err);
const success = (response: AxiosResponse) => setSuccess<any>(response);

export function fiberTraceOut(baseURL: string, accessToken: string) {
  function makeRequest(
    opts: api.FiberCableNodeFiberTraceOutOptions
  ): _request.Request<telco.fiber_cable_node.FiberTraceOut, _error.ObjectError> {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const params = getParams(accessToken, opts);
    const url = `${baseURL}/${NAMESPACE}/${RESOURCE}/${opts.id}/fiber_trace_out?${params}`;
    const request = axios.get(url, { cancelToken }).then(success).catch(error);
    return { cancel, request };
  }
  return makeRequest;
}
