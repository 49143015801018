import * as React from 'react';
import { LoadingOptions } from 'types';

import { _Props, _State } from './types';

export class LoadState extends React.PureComponent<_Props, _State> {
  static getDerivedStateFromProps(nextProps: _Props, prevState: _State): null | Partial<_State> {
    const nextState: Partial<_State> = {};
    if (nextProps.error !== prevState.error) {
      nextState.error = nextProps.error;
    }
    if (nextProps.isLoading !== prevState.isLoading) {
      nextState.isLoading = nextProps.isLoading;
    }
    if (nextProps.pastDelay !== prevState.pastDelay) {
      nextState.pastDelay = nextProps.pastDelay;
    }
    if (nextProps.timedOut !== prevState.timedOut) {
      nextState.timedOut = nextProps.timedOut;
    }
    return nextState;
  }

  constructor(props: _Props) {
    super(props);
    this.state = {
      error: props.error,
      isLoading: props.isLoading,
      pastDelay: props.pastDelay,
      timedOut: props.timedOut,
    };
  }

  componentDidMount() {
    this.props.setState(this.state);
  }

  componentDidUpdate(prevProps: _Props) {
    const errorIsChanged = prevProps.error !== this.state.error;
    const isLoadingIsChanged = prevProps.isLoading !== this.state.isLoading;
    const pastDelayIsChanged = prevProps.pastDelay !== this.state.pastDelay;
    const timedOutIsChanged = prevProps.timedOut !== this.state.timedOut;
    if (errorIsChanged || isLoadingIsChanged || pastDelayIsChanged || timedOutIsChanged) {
      const nextState: Partial<LoadingOptions> = {};
      if (errorIsChanged) {
        nextState.error = this.state.error;
      }
      if (isLoadingIsChanged) {
        nextState.isLoading = this.state.isLoading;
      }
      if (pastDelayIsChanged) {
        nextState.pastDelay = this.state.pastDelay;
      }
      if (timedOutIsChanged) {
        nextState.timedOut = this.state.timedOut;
      }
      this.props.setState(nextState);
    }
  }

  render() {
    return null;
  }
}
