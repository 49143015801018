import { Reducer } from 'redux';
import { VisualizationData, VisualizationState } from 'types';

const EXPAND = 'store/ui/actions/expand-viz';
const CLOSE = 'store/ui/actions/close-viz';

type ExpandVizAction = { type: typeof EXPAND, visualization: VisualizationData };
type CloseVizAction = { type: typeof CLOSE };
export type VisualizationAction = ExpandVizAction | CloseVizAction;

// action creators
export const expandVisualization = (visualization: VisualizationData): ExpandVizAction => ({
  type: EXPAND,
  visualization
});
export const closeVisualization = (): CloseVizAction => ({ type: CLOSE });

// reducer
const reducer: Reducer<VisualizationState, VisualizationAction> = (state, action) => {
  switch (action.type) {
    case EXPAND: return { ...state, ...action.visualization };
    case CLOSE: return null;
    default: return state!;
  }
};
export default reducer;
